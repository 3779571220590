export const getPostType = (format: string) => {
  if (format.includes('video')) {
    return 'video';
  } else if (format.includes('Graphic')) {
    return 'image';
  } else if (format.includes('Carousel')) {
    return 'crousel';
  }
};
export const getPostTypeForFrontEnd = (format: string) => {
  if (format.includes('video')) {
    return 'Video';
  } else if (format.includes('image')) {
    return 'Graphic';
  } else if (format.includes('crousel')) {
    return 'Carousel';
  }
};
