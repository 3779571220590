export const fetchUpdatedContent = (
  captions: string[],
  postTypes: string[],
  platform: string,
  currentImpressions: number
) => {
  const bodyData = {
    captions: captions,
    current_impressions: 250,
    platform: 'instagram',
    start_date: '2024-10-21',
    last_post_types: ['image', 'video', 'image', 'video', 'crousel'] // this should be 5
  };

  return fetch('https://ai.cluetech.co.uk/content_plan_model', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Connection: 'keep-alive'
    },
    body: JSON.stringify(bodyData)
  })
    .then(async (res) => {
      const response = await res.json();
      if (response.output.length === 0) {
        return null;
      }
      return response;
    })
    .catch((err) => {
      console.log('Error getting updated content', err);
      return err;
    });
};
