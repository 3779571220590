import { useState, useEffect, useRef } from 'react';
import React from 'react';
import { toast } from 'sonner';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  getTabs,
  groupByWeek,
  saveUpdatedPostsInBackend,
  refreshPost
} from './component';
import SignUpCircles from '../../../../assets/Sign-In-Circles.png';
import DaysLoader from '../day-of-awareness/component/days-loader';
import { EyeIcon } from '@heroicons/react/24/solid';

import { getPostType } from './component/getPostType';
import { fetchUpdatedContent } from './component/fetchUpdatedContent';
import { updateWeeklyContent } from './component/updateWeeklyContent';
interface ContentInterface {
  Caption: string;
  Date: string;
  Format: string;
  Idea: string;
  Video_Idea: string;
  PredictedImpressions: number;
}
const WeeklyContent = () => {
  const [currentTab, setCurrentTab] = useState(0);
  const [weeklyOrganizedData, setWeeklyOrganizedData] = useState<any>([]);
  const [selectedPost, setSelectedPost] = useState<any>();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [isEditing, setIsEditing] = useState(false); // State to track editing mode
  const [editedCaption, setEditedCaption] = useState('');
  const [showLoader, setShowLoader] = useState(true);
  const [selectedTime, setSelectedTime] = useState(null);
  const [isRefreshPressed, setIsRefreshPressed] = useState(false);
  const [selectedContent, setSelectedContent] = useState(false);
  const [textChanged, setTextChanged] = useState(false);
  const [refreshLoader, setRefreshLoader] = useState<any>();
  const [tabs, setTabs] = useState([{}]);
  const [updateContent, setUpdateContent] = useState(false);
  const location = useLocation();
  const email = localStorage.getItem('UserEmail');
  var planId: string;
  const tableRef = useRef(null);

  const contentData = location.state;
  planId = contentData?.planId
    ? contentData?.planId
    : localStorage.getItem('PlanId') || '';
  //get plan id from previous screen if not exist then get from localstorage
  const navigate = useNavigate();
  const handleSelectTab = (index: any) => {
    setCurrentTab(index);
  };
  const handleTimeCheck = (event: any) => {
    setSelectedTime(event.target.value);
  };

  useEffect(() => {
    handleGenerateContent(false);
  }, []);
  var captions: string[] = [];
  var postTypes: any[] = [];
  let count = 0;
  useEffect(() => {
    if (count === 0 && updateContent !== false) {
      Object.entries(weeklyOrganizedData).forEach(
        (week: any, index: number) => {
          week[1].forEach((day: any) => {
            captions.push(day.Caption);
            postTypes.push(getPostType(day.Format));
          });
        }
      );
      count++;
      setShowLoader(true);
      setUpdateContent(true);
    }
  }, [weeklyOrganizedData, updateContent]);
  useEffect(() => {
    if (updateContent !== false) {
      fetchUpdatedContent(captions, postTypes, 'instagram', 250)
        .then((res) => {
          console.log('res', res);

          if (res !== null) {
            const race = updateWeeklyContent(weeklyOrganizedData, res);
            console.log({ race });
            setUpdateContent(false);
            setWeeklyOrganizedData(race);
            setShowLoader(false);
          } else {
            setShowLoader(true);
            handleGenerateContent(false);
          }

          // setShowLoader(false);
        })
        .catch((err) => {
          console.log('Error fetch updated content', err);
        });
    }
  }, [updateContent]);
  const handleGenerateContent = (regenerate: boolean) => {
    setShowLoader(true);
    if (planId === 'undefined') {
      setShowLoader(false);
      toast.error(
        'Please make sure you have completed the create plan flow before accessing this page or Try Reloading The Page Again If You Have Completed The Create Plan Flow!',
        { duration: 5000 }
      );
    } else if (planId) {
      fetch(
        `${process.env.REACT_APP_BACKEND_URI}/plan/get-content-strategy/${email}/${planId}?regenerate=${regenerate}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
        .then(async (res) => {
          if (res.ok) {
            const data = await res.json();
            setTabs(
              getTabs(groupByWeek(data.data, parseInt(data.perWeekPosts)))
            );
            setWeeklyOrganizedData(groupByWeek(data.data, data.perWeekPosts));
            setShowLoader(false);
            setUpdateContent(true);
          } else {
            toast.error(
              'Please make sure you have completed the create plan flow before accessing this page or Try Reloading The Page Again If You Have Completed The Create Plan Flow!'
            );
            setShowLoader(false);
          }
        })
        .catch((err) => {
          console.log('Error adding user details!', err);
          toast.error(
            'Please make sure you have completed the create plan flow before accessing this page or Try Reloading The Page Again If You Have Completed Create The Plan Flow!'
          );
          setShowLoader(false);
        });
    }
  };

  const handleNext = async () => {
    console.log({ planId });
    const bodyData = {
      planId: planId,
      lastStep: 'weekly-content-strategy'
    };
    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URI}/plan/update`,
        {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(bodyData)
        }
      );
      console.log('rs', response);
      if (response.ok) {
        const data = await response.json();
        console.log({ data });

        if (data?.code == 200) {
          navigate('/content-development', { state: { weeklyOrganizedData } });
        } else {
          throw new Error(data.status);
        }
      } else {
        throw new Error('Unable to connect to Backend');
      }
    } catch (error: any) {
      console.log(error.message);
      console.log(error.msg);
      // Handle network errors or exceptions
      console.error('Error:', error);
    }
  };

  const updatePost = () => {
    // Check if weeklyOrganizedData is defined and is an object
    if (
      typeof weeklyOrganizedData === 'object' &&
      weeklyOrganizedData !== null
    ) {
      // Get the keys (week numbers) of weeklyOrganizedData
      const weekNumbers = Object.keys(weeklyOrganizedData);

      // Iterate over each week number
      weekNumbers.forEach((weekNumber) => {
        // Get the array of content for the current week
        const contentArray = weeklyOrganizedData[weekNumber];

        // Check if contentArray is an array
        if (Array.isArray(contentArray)) {
          // Map over the content array and update the caption of the selected post
          const updatedContentArray = contentArray.map((content) => {
            if (content === selectedPost) {
              setSelectedPost({
                ...content,
                Caption: editedCaption
              });
              return {
                ...content,
                Caption: editedCaption
              };
            } else return content;
          });

          // Update the content array for the current week in weeklyOrganizedData
          weeklyOrganizedData[weekNumber] = updatedContentArray;
        }
      });

      // Update the state with the modified weeklyOrganizedData
      setWeeklyOrganizedData({ ...weeklyOrganizedData });
      console.log('####', weeklyOrganizedData);
      // destructure weekly divided into array
      const combinedArray = Object.values(weeklyOrganizedData).flat();
      saveUpdatedPostsInBackend(
        planId || '',
        'weekly-content-strategy',
        combinedArray
      );
      setIsEditing(false); // Disable editing mode after saving
    } else {
      // Handle the case where weeklyOrganizedData is not in the expected format
      console.error('weeklyOrganizedData is not in the expected format');
      // You can add an error message or any other appropriate handling here
    }
  };

  return (
    <div className="relative w-full items-center min-h-screen flex flex-col ">
      <img
        className="hidden md:flex absolute w-[300px] h-[293px] right-0 -rotate-90 bottom-0 -z-10"
        src={SignUpCircles}
        alt={'details circles'}
      />

      <div className="flex flex-col w-full max-w-7xl px-10 bg-white mb-10">
        <div className="flex flex-col items-center w-full mt-10">
          <p className="text-black text-2xl md:text-4xl font-medium">Output</p>
          <p className="text-black text-3xl md:text-5xl font-extrabold mb-[54px] text-center">
            {'Weekly Content Strategy'}
          </p>
        </div>
        {showLoader ? (
          <DaysLoader screen="weekly-content-strategy" />
        ) : (
          <div>
            <div>
              <div>
                <div className="sm:hidden">
                  <label htmlFor="tabs" className="sr-only">
                    Select a tab
                  </label>
                  <select
                    id="tabs"
                    name="tabs"
                    value={currentTab}
                    onChange={(e) => {
                      handleSelectTab(parseInt(e.target.value));
                    }}
                    className="block w-full rounded-md border-rose-500 py-2 pl-3 pr-10 text-base focus:border-rose-500 focus:outline-none focus:ring-rose-500 sm:text-sm"
                  >
                    {tabs.map((tab: any) => (
                      <option
                        className="text-rose-500"
                        key={tab.name}
                        value={tab.index}
                      >
                        {tab.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="hidden sm:block" ref={tableRef}>
                  <div className="flex justify-between">
                    <div className="flex w-full justify-start items-center border-gray-200">
                      <nav
                        className="flex border border-cyan-500 rounded-tr-lg rounded-tl-lg"
                        aria-label="Tabs"
                      >
                        {tabs.map((tab: any) => (
                          <a
                            key={tab.name}
                            href={tab.href}
                            onClick={() => handleSelectTab(tab.index)}
                            className={`${
                              currentTab === tab.index
                                ? `border-rose-500 bg-rose-500 text-white ${
                                    tab.index === 4
                                      ? 'rounded-tr-lg'
                                      : tab.index === 0
                                        ? 'rounded-tl-lg'
                                        : ''
                                  }`
                                : 'text-rose-500 hover:border-rose-500 hover:text-white hover:bg-rose-500'
                            } ${
                              tab.index === 4
                                ? 'rounded-tr-lg'
                                : tab.index === 0
                                  ? 'rounded-tl-lg'
                                  : ''
                            } whitespace-nowrap border-cyan-500 border-l py-2 px-4 text-sm font-medium`}
                            aria-current={
                              currentTab === tab.index ? 'page' : undefined
                            }
                          >
                            {tab.name}
                          </a>
                        ))}
                      </nav>
                    </div>
                    <button
                      className="flex justify-center h-10 items-center bg-teal-400 rounded-lg text-center text-white w-60 m-1 gap-x-4"
                      onClick={() => {
                        navigate('/view-response', {
                          state: { planId: planId }
                        });
                      }}
                    >
                      <EyeIcon width={20} /> {'View Response'}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="inline-block max-w-screenxl align-middle bg-white ">
              <table className="min-w-full divide-y divide-gray-300">
                <thead>
                  <tr className=" border-gray-300  border rounded-t-lg">
                    <th
                      scope="col"
                      className="border-r py-3.5 px-3 text-left text-sm font-semibold text-gray-900"
                    >
                      Day 1
                    </th>
                    <th
                      scope="col"
                      className="border-r px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Day 2
                    </th>
                    <th
                      scope="col"
                      className="border-r px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Day 3
                    </th>
                    <th
                      scope="col"
                      className="border-r px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Day 4
                    </th>
                    <th
                      scope="col"
                      className="border-r px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Day 5
                    </th>
                    <th
                      scope="col"
                      className="border-r px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Day 6
                    </th>
                    <th
                      scope="col"
                      className="border-l px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Day 7
                    </th>
                  </tr>
                </thead>
                <tbody className="">
                  {currentTab !== null &&
                    weeklyOrganizedData[currentTab + 1] && (
                      <tr className="border-b  border-l h-full">
                        {weeklyOrganizedData[currentTab + 1].map(
                          (content: ContentInterface, index: number) => (
                            <td
                              className={`border-r border-l py-4 px-3 text-sm text-gray-500 bg-white h-20 overflow-hidden ${
                                selectedIndex === index
                                  ? 'selected-td bg-white :border-[1px] border-cyan-500'
                                  : ''
                              }`}
                              onClick={() => {
                                setSelectedPost(content);
                                console.log('$$$$', content);
                                setSelectedIndex(index);
                                setEditedCaption(content.Caption);
                              }}
                            >
                              <div className="h-full flex flex-col space-y-5">
                                <div className="flex space-x-2 ">
                                  <span className="min-w-16 font-bold">
                                    Date:{' '}
                                  </span>
                                  <span>{content.Date}</span>
                                </div>
                                <div className="flex space-x-2 ">
                                  <span className="min-w-16 font-bold">
                                    Format:{' '}
                                  </span>
                                  <span className=" flex bg-rose-50 text-rose-500 px-2 rounded-lg">
                                    {content.Format}
                                  </span>
                                </div>
                                <div className="flex space-x-2 ">
                                  <span className="min-w-16 font-bold">
                                    {' '}
                                    Idea:{' '}
                                  </span>
                                  <span className=" flex bg-rose-50 text-rose-500 px-2 rounded-lg">
                                    {content.Idea}
                                  </span>
                                </div>
                                {content.Video_Idea ? (
                                  <div className="flex space-x-2 ">
                                    <span className="min-w-16 font-bold">
                                      Video Idea:{' '}
                                    </span>
                                    <span className="flex bg-rose-50 text-rose-500 px-2 rounded-lg">
                                      {content.Video_Idea}
                                    </span>
                                  </div>
                                ) : (
                                  ''
                                )}
                                <div className="flex space-x-2">
                                  <span className="min-w-16 font-bold">
                                    Caption:{' '}
                                  </span>
                                  <span className="flex bg-rose-50 text-rose-500 px-2 rounded-lg overflow-y-auto h-full">
                                    {content.Caption}
                                  </span>
                                </div>
                                <div className="flex space-x-2">
                                  <span className="min-w-16 font-bold">
                                    PredictedImressions:{' '}
                                  </span>
                                  <span className="flex bg-rose-50 text-rose-500 px-2 rounded-lg overflow-y-auto h-full">
                                    {content.PredictedImpressions}
                                  </span>
                                </div>
                              </div>
                            </td>
                          )
                        )}
                      </tr>
                    )}
                </tbody>
              </table>
              {/* <div className="mt-8 w-full">
                <p className="font-bold text-xl  ">
                  Purpose Of
                  <span className="font-bold text-xl text-rose-500 ml-1 ">
                    Post
                  </span>
                </p>
              </div> */}
              <div className="w-full flex items-center justify-between mt-4 space-x-5">
                {/* <input
                  value="Sales"
                  className="flex w-full border border-cyan-500 px-5 py-2 rounded-md max-w-lg"
                /> */}

                {/* <div className="w-full max-w-lg flex justify-start items-center">
                  <button
                    className="justify-center items-center w-fit px-8 rounded-md border text-white bg-rose-500 border-rose-500 hover:bg-rose-500 h-10"
                    onClick={() => {
                      setIsEditing(true); // Enable editing mode
                      setEditedCaption(selectedPost?.Caption || '');
                    }}
                    disabled={selectedPost ? false : true}
                  >
                    Edit
                  </button>
                </div> */}
              </div>
              <div className="flex flex-col w-full mt-8 bg-white px-  ">
                {selectedPost && (
                  <div className="flex space-x-5 w-full">
                    <div className="flex flex-col space-y-5 w-full">
                      {/* <div
                        className={`flex flex-col w-full max-w-lg py-5 ${isEditing ? 'border-cyan-500' : ''} border rounded-md max-h-md px-8`}
                      > */}
                      {/* <div className=" flex justify-between max-w-lg">
                          <span className="font-bold text-xl  ">
                            Post Content :
                          </span>
                          {isEditing && (
                            <button
                              className="bg-[#01C3C3] text-white w-24 h-8 rounded-md"
                              onClick={async () => {
                                setRefreshLoader(true);
                                const caption = await refreshPost(
                                  planId || '',
                                  selectedPost,
                                  setRefreshLoader
                                );
                                // setSelectedPost({ ...selectedPost, Caption: caption });
                                setEditedCaption(caption);
                                setIsRefreshPressed(true);
                              }}
                            >
                              Refresh
                            </button>
                          )}
                        </div> */}
                      {/* {isEditing ? (
                          // Display textarea field when editing mode is enabled
                          <textarea
                            // type="text"
                            value={editedCaption}
                            onChange={(e) => {
                              setEditedCaption(e.target.value);
                            }}
                            className="min-h-24 h-full px-5 py-2 text-xs bg-white rounded-lg border border-slate-300 mb-5 focus:outline-0 focus:outline-cyan-500 resize-none mt-5 focus:ring-1 focus:ring-cyan-500 focus:border-cyan-500"
                          />
                        ) : (

                          <p className="mt-8">
                            {refreshLoader && <DaysLoader />}{' '}
                            {!refreshLoader &&
                              (isRefreshPressed
                                ? editedCaption
                                : selectedPost?.Caption)}
                          </p>
                        )} */}
                      {/* </div> */}
                      <div
                        className={`flex flex-col w-full max-w-lg py-5 ${isEditing ? 'border-cyan-500' : ''} border rounded-md max-h-md px-8`}
                      >
                        <div className="flex justify-between">
                          <span className="font-bold text-xl  ">
                            Post Caption :
                          </span>
                          <button
                            className="justify-center items-center w-fit px-8 rounded-md border text-white bg-rose-500 border-rose-500 hover:bg-rose-500 h-10"
                            onClick={() => {
                              setIsEditing(true); // Enable editing mode
                              setEditedCaption(selectedPost?.Caption || '');
                            }}
                            disabled={selectedPost ? false : true}
                          >
                            Edit
                          </button>
                          <button
                            className="justify-center items-center w-fit px-8 rounded-md border text-white bg-rose-500 border-rose-500 hover:bg-rose-500 h-10"
                            onClick={() => {
                              navigator.clipboard.writeText(
                                selectedPost.Caption
                              );
                              toast.info('Caption copied!');
                            }}
                          >
                            copy
                          </button>
                          {isEditing && (
                            <button
                              className="bg-[#01C3C3] text-white w-24 h-8 rounded-md"
                              onClick={async () => {
                                setRefreshLoader(true);
                                const caption = await refreshPost(
                                  planId || '',
                                  selectedPost,
                                  setRefreshLoader
                                );
                                // setSelectedPost({ ...selectedPost, Caption: caption });
                                setEditedCaption(caption);
                                console.log('edcption', editedCaption);
                                setIsRefreshPressed(true);
                              }}
                            >
                              Refresh
                            </button>
                          )}
                        </div>
                        {isEditing ? (
                          // Display input field when editing mode is enabled
                          <textarea
                            // type="text"
                            value={editedCaption}
                            onChange={(e) => {
                              setEditedCaption(e.target.value);
                            }}
                            className="min-h-24 h-full px-5 py-2 text-xs bg-white rounded-lg border border-slate-300 mb-5 focus:outline-0 focus:outline-cyan-500 resize-none mt-5 focus:ring-1 focus:ring-cyan-500 focus:border-cyan-500"
                          />
                        ) : (
                          // Display caption text when not in editing mode

                          <p className="mt-8">
                            {refreshLoader && <DaysLoader />}{' '}
                            {/* Show loader if refreshLoader is true */}
                            {!refreshLoader &&
                              (isRefreshPressed
                                ? editedCaption
                                : selectedPost?.Caption)}
                          </p>
                        )}

                        {/* {isEditing && ( // Display save button when in editing mode
                          <button
                            className="justify-center items-center w-full border h-10"
                            onClick={() => {
                              updatePost();
                              setEditedCaption('');
                              setSelectedPost(null);
                            }}
                          >
                            Save
                          </button>
                        )} */}
                      </div>
                      {/* <div className={`flex flex-col w-full max-w-lg py-5 ${isEditing ? 'border-cyan-500' : ''} border rounded-md max-h-md px-8`}>
                       
                        {isEditing ? (
                          // Display input field when editing mode is enabled
                          <textarea
                            // type="text"
                            value={editedCaption}
                            onChange={(e) => {
                              setEditedCaption(e.target.value);
                            }}
                            className="min-h-24 h-full px-5 py-2 text-xs bg-white rounded-lg border border-slate-300 mb-5 focus:outline-0 focus:outline-cyan-500 resize-none mt-5 focus:ring-1 focus:ring-cyan-500 focus:border-cyan-500"
                          />
                        ) : (

                          <p className="mt-8">
                            {refreshLoader && <DaysLoader />}{' '}
                            {!refreshLoader &&
                              (isRefreshPressed
                                ? editedCaption
                                : selectedPost?.Caption)}
                          </p>
                        )}

                        
                      </div> */}
                    </div>

                    <div
                      className={`flex flex-col w-full max-w-lg py-5 ${isEditing ? 'border-cyan-500' : ''} border rounded-md max-h-md px-8`}
                    >
                      <div className="w-full flex justify-between">
                        <span className="font-bold text-xl  ">
                          Suggested Time to Post
                        </span>
                        {isEditing && (
                          <button
                            className="bg-[#01C3C3] text-white w-24 h-8 rounded-md"
                            onClick={async () => {
                              setRefreshLoader(true);
                              const caption = await refreshPost(
                                planId || '',
                                selectedPost,
                                setRefreshLoader
                              );
                              // setSelectedPost({ ...selectedPost, Caption: caption });
                              setEditedCaption(caption);
                              console.log('edcption', editedCaption);
                              setIsRefreshPressed(true);
                            }}
                          >
                            Refresh
                          </button>
                        )}
                      </div>
                      <div className="mt-8 space-y-5 flex flex-col">
                        <div className="flex items-center space-x-6">
                          {isEditing && (
                            <input
                              type="radio"
                              value="08:00 AM"
                              checked={selectedTime === '08:00 AM'}
                              onChange={handleTimeCheck}
                              className="form-radio h-4 w-4 border-cyan-300 text-cyan-500 focus:ring-cyan-500"
                            />
                          )}
                          <span className="border border-rose-500 p-2 px-5 w-fit rounded-3xl">
                            08:00 AM
                          </span>
                        </div>
                        <div className="flex items-center space-x-6">
                          {isEditing && (
                            <input
                              type="radio"
                              value="09:00 AM"
                              checked={selectedTime === '09:00 AM'}
                              onChange={handleTimeCheck}
                              className="h-4 w-4 border-cyan-300 text-cyan-500 focus:ring-cyan-500"
                            />
                          )}
                          <span className="border border-rose-500 p-2 px-5 w-fit rounded-3xl">
                            09:00 AM
                          </span>
                        </div>
                        <div className="flex items-center space-x-6">
                          {isEditing && (
                            <input
                              type="radio"
                              value="10:00 AM"
                              checked={selectedTime === '10:00 AM'}
                              onChange={handleTimeCheck}
                              className="h-4 w-4 border-cyan-300 text-cyan-500 focus:ring-cyan-500"
                            />
                          )}
                          <span className="border border-rose-500 p-2 px-5 w-fit rounded-3xl">
                            10:00 AM
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {isEditing ? (
                  <div className="flex justify-center items-center gap-5 w-full mt-10 mb-10">
                    {/* <button
                    className="flex justify-center items-center px-4 lg:px-10 py-2 bg-white border-2 border-rose-500 rounded-lg text-center text-rose-500 text-lg font-bold"
                    onClick={() => {
                      navigate(-1);
                    }}
                  >
                    {'Back'}
                  </button> */}
                    <button
                      className="flex justify-center items-center px-28 md:px-40 lg:px-32 py-3 bg-white border-2 border-cyan-500 rounded-lg text-center text-cyan-500 text-lg font-bold"
                      onClick={() => {
                        handleGenerateContent(true);
                      }}
                    >
                      {'Share'}
                    </button>
                    <div>
                      {isRefreshPressed ? (
                        <button
                          className="px-28 md:px-40 lg:px-32 py-3 bg-rose-500 hover:bg-rose-400 rounded-lg  text-center text-white text-lg font-semibold"
                          onClick={() => {
                            if (editedCaption !== selectedPost.Caption) {
                              setTextChanged(true);
                            }
                          }}
                        >
                          {'Update'}
                        </button>
                      ) : (
                        <button
                          className="px-28 md:px-40 lg:px-32 py-3 bg-rose-500 hover:bg-rose-400 rounded-lg  text-center text-white text-lg font-semibold"
                          onClick={() => {
                            if (editedCaption !== selectedPost.Caption) {
                              setTextChanged(true);
                            } else {
                              handleNext();
                            }
                          }}
                        >
                          {'Update'}
                        </button>
                      )}
                    </div>
                  </div>
                ) : (
                  <div className="flex justify-center items-center gap-5 w-full mt-10 mb-10">
                    <button
                      className="flex justify-center items-center px-4 lg:px-10 py-2 bg-white border-2 border-rose-500 rounded-lg text-center text-rose-500 text-lg font-bold"
                      onClick={() => {
                        navigate(-1);
                      }}
                    >
                      {'Back'}
                    </button>
                    <button
                      className="flex justify-center items-center px-4 lg:px-10 py-2 bg-white border-2 border-rose-500 rounded-lg text-center text-rose-500 text-lg font-bold"
                      onClick={() => {
                        handleGenerateContent(true);
                      }}
                    >
                      {'Regenerate Content'}
                    </button>

                    <div>
                      {isRefreshPressed ? (
                        <button
                          className="px-28 md:px-40 lg:px-32 py-3 bg-rose-500 hover:bg-rose-400 rounded-lg  text-center text-white text-lg font-semibold"
                          onClick={() => {
                            updatePost();
                            setIsRefreshPressed(false);
                            setSelectedPost(null);
                          }}
                        >
                          {'Update'}
                        </button>
                      ) : (
                        <button
                          className="px-28 md:px-40 lg:px-32 py-3 bg-rose-500 hover:bg-rose-400 rounded-lg  text-center text-white text-lg font-semibold"
                          onClick={() => {
                            handleNext();
                          }}
                        >
                          {'Next'}
                        </button>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
            {textChanged && (
              <div className="fixed inset-0 flex items-center justify-center">
                <div className="absolute inset-0 bg-black opacity-50 pointer-events-none"></div>
                <div className="bg-white flex flex-col justify-start space-x-8 max-w-md border border-teal-500 rounded-lg h-full max-h-[180px] p-5 z-20">
                  <p className="font-semibold mb-8">
                    Do you want to save the changes made to the content?
                  </p>
                  <div className="flex justify-center space-x-10">
                    <button
                      className="justify-center items-center w-full max-w-36 rounded-md border text-white bg-teal-500 border-teal-500 hover:bg-teal-500 h-10"
                      onClick={() => {
                        updatePost();
                        setIsRefreshPressed(false);
                        setSelectedPost(null);
                        setTextChanged(false);
                      }}
                    >
                      Save
                    </button>
                    <button
                      className="justify-center items-center w-full max-w-36 rounded-md border text-white bg-rose-500 border-rose-500 hover:bg-rose-500 h-10"
                      onClick={() => {
                        setIsEditing(false);
                        setTextChanged(false);
                      }}
                    >
                      Discard
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default WeeklyContent;
