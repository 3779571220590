import React, { useState, useEffect, useRef } from 'react';
import SignUpCircles from '../../../../assets/Sign-In-Circles.png';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
interface PostInterface {
  caption: string;
  date: string;
  time: string;
  type: string;
}
const people = [
  {
    id: 1,
    postName: 'Sale Post',
    description:
      'Every large design company whether it’s a multi-national branding.',
    tags: '# clue # Clue content',
    postDate: '03.05.2024',
    time: '01.05.2022',
    status: 'pending'
  },
  {
    id: 2,
    postName: 'Sale Post2',
    description:
      'Every large design company whether it’s a multi-national branding.2',
    tags: '# clue # Clue content2',
    postDate: '02.05.2024',
    time: '02.05.2025',
    status: 'completed'
  },
  {
    id: 3,
    postName: 'Sale Post3',
    description:
      'Every large design company whether it’s a multi-national branding.3',
    tags: '# clue # Clue content3',
    postDate: '03.05.2024',
    time: '03.05.2025',
    status: 'error'
  }
  // More people...
];
const tabs = [
  {
    name: 'Analysis',
    description: `1 Your post has 0 comments and 0 likes.- Your post has a caption "demo" and it is an image type`
  },
  {
    name: 'Improvement',
    description: `2 Your post has 0 comments and 0 likes.- Your post has a caption "demo" and it is an image type`
  },
  {
    name: 'Prediction',
    description: `3 Your post has 0 comments and 0 likes.- Your post has a caption "demo" and it is an image type`
  },
  {
    name: 'Rating',
    description: `4 Your post has 0 comments and 0 likes.- Your post has a caption "demo" and it is an image type`
  }
  // More people...
];
const Report = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [currentTab, setCurrentTab] = useState(0);
  const [userPosts, setUserPosts] = useState<any[]>([]);
  const tableRef = useRef(null);
  const [searchTerm, setSearchTerm] = useState('');
  const handleSelectTab = (index: any) => {
    setCurrentTab(index);
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option: any) => {
    console.log(option); // Handle the selected option here
    setIsOpen(false);
  };

  const downloadPDF = () => {
    console.log('kashkljslkjdlkasjdlkasjdlkjsa');

    const input = tableRef.current;
    if (input) {
      html2canvas(input).then((canvas: any) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF();
        const imgProps = pdf.getImageProperties(imgData);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

        pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
        pdf.save('report.pdf');
      });
    }
  };
  const getPosts = () => {
    const userData: any = localStorage.getItem('InstagramUserPosts');
    const parsedData = userData ? JSON.parse(userData) : [];
    var postType: string;
    if (parsedData) {
      const posts = parsedData?.posts?.map((post: any) => {
        // const date = new Date(post?.taken_at * 1000);
        // if (post.media_name === 'post') postType = 'Image';
        // else if (post.media_name === 'album') postType = 'Carousel';
        // else if (post.media_name === 'reel') postType = 'Video';
        return {
          caption: post?.caption
          // date: post?.taken_at
          //   ? date.getDate() + '-' + date.getMonth() + '-' + date.getFullYear()
          //   : '',
          // time: post?.taken_at
          //   ? date.getUTCHours() +
          //     ':' +
          //     date.getUTCMinutes() +
          //     ':' +
          //     date.getUTCSeconds()
          //   : '',
          // type: postType
        };
      });
      console.log('first', posts.length);

      return posts;
    }
  };
  useEffect(() => {
    const posts = getPosts();
    setUserPosts(posts);
  }, []);
  return (
    <div className="relative w-full items-center min-h-screen px-10">
      <img
        className="hidden md:flex absolute w-[300px] h-[293px] right-0 -rotate-90 bottom-0 -z-10"
        src={SignUpCircles}
        alt={'details circles'}
      />
      <div className="flex flex-col w-full max-w-7xl px-10 bg-white mb-10">
        <div className="flex flex-col items-center w-full mt-10">
          <p className="text-black text-3xl md:text-5xl font-extrabold mb-[54px] text-center">
            {'Report'}
          </p>
        </div>
      </div>
      <div className="flex justify-between items-center w-full mb-10">
        <p className="text-black text-2xl md:text-4xl font-medium">
          {'Report and analysis'}
        </p>
        <div className="flex items-center space-x-5 relative">
          <button
            className="bg-rose-500 text-white w-fit h-fit rounded-md px-3 py-2 text-sm hover:bg-rose-400"
            onClick={downloadPDF}
          >
            Download PDF
          </button>
          <button
            onClick={toggleDropdown}
            className="bg-teal-500 text-white w-fit h-fit rounded-md px-3 py-2 text-sm hover:bg-teal-400"
          >
            Report
          </button>
          {isOpen && (
            <div className="absolute right-5 top-10 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div className="py-2" onMouseLeave={() => setIsOpen(false)}>
                <a
                  href="#"
                  onClick={() => handleOptionClick('today')}
                  className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                >
                  Today
                </a>
                <a
                  href="#"
                  onClick={() => handleOptionClick('last 7 days')}
                  className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                >
                  Last 7 Days
                </a>
                <a
                  href="#"
                  onClick={() => handleOptionClick('last month')}
                  className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                >
                  Last Month
                </a>
                <a
                  href="#"
                  onClick={() => handleOptionClick('last year')}
                  className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                >
                  Last Year
                </a>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="flex justify-between items-center w-full mb-10">
        <div className="flex items-center space-x-5">
          <div className="flex items-center relative">
            <input
              className="w-full max-w-sm pl-8 pr-2 py-2 rounded-2xl border border-teal-400 focus:border-[1px] focus:outline-none focus:ring-1 focus:ring-teal-500 focus:border-teal-500"
              placeholder={'Search'}
              type="search"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setSearchTerm(e.target.value);
                if (e.target.value.length !== 0) {
                  const filteredPosts = userPosts.filter(
                    (post: PostInterface) => {
                      return post.caption.toLowerCase().includes(searchTerm);
                    }
                  );
                  setUserPosts(filteredPosts);
                } else {
                  setUserPosts(getPosts());
                }
              }}
            />
            <MagnifyingGlassIcon className="absolute top-[11px] left-2 w-5 h-5 text-teal-500" />
          </div>
        </div>
      </div>
      <div className="px-4">
        {userPosts.length > 0 ? (
          <div className="mt-8 flow-root">
            <div className="-mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div
                className="flex min-w-full align-middle sm:px-6 lg:px-8"
                ref={tableRef}
              >
                <table className="min-w-full divide-y divide-gray-300">
                  <thead>
                    <tr>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                      >
                        # Id
                      </th>
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0"
                      >
                        Posts Name / Title and Description
                      </th>

                      {/* <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Post Date
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Time
                      </th> */}
                    </tr>
                  </thead>
                  <tbody className="">
                    {userPosts?.map((post: PostInterface, index) => (
                      <tr key={index} className="border-b border-gray-200">
                        <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">
                          {index + 1}
                        </td>
                        <td
                          className="max-w-52 truncate py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0 space-y-2"
                          title={post.caption}
                        >
                          <p>{post.type}</p>
                          <span className="text-xs text-gray-400 ">
                            {post.caption}
                          </span>
                        </td>
                        <td className="whitespace-wrap px-3 py-4 text-sm text-gray-900">
                          {post.date}
                        </td>
                        <td className="whitespace-wrap px-3 py-4 text-sm text-gray-900">
                          {post.time}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="flex w-full items-center justify-center mt-10">
              <button className="bg-teal-500 text-white w-fit h-fit rounded-md px-3 py-2 text-sm hover:bg-teal-400">
                Load more
              </button>
            </div>
          </div>
        ) : (
          <p>
            Your instagram account has no posts or Please connect your instagram
            account.
          </p>
        )}
      </div>
      <div className="py-10">
        <p className="text-black text-2xl md:text-4xl font-medium">
          {'Suggestion'}
        </p>
        <div className="mt-8">
          <div>
            <div className="sm:hidden">
              <label htmlFor="tabs" className="sr-only">
                Select a tab
              </label>
              <select
                id="tabs"
                name="tabs"
                value={currentTab}
                onChange={(e) => handleSelectTab(parseInt(e.target.value))}
                className="block w-full rounded-md border-rose-500 py-2 pl-3 pr-10 text-base focus:border-rose-500 focus:outline-none focus:ring-rose-500 sm:text-sm"
              >
                {tabs.map((tab, index) => (
                  <option key={tab.name} value={index}>
                    {tab.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="hidden sm:block">
              <div className="flex w-full justify-start items-center border-gray-200">
                <nav
                  className="flex border border-cyan-500 rounded-tr-lg rounded-tl-lg"
                  aria-label="Tabs"
                >
                  {tabs.map((tab, index) => (
                    <button
                      key={tab.name}
                      onClick={() => handleSelectTab(index)}
                      className={`${
                        currentTab === index
                          ? 'border-rose-500 bg-rose-500 text-white'
                          : 'text-rose-500 hover:border-rose-500 hover:text-white hover:bg-rose-500'
                      } ${
                        index === 3
                          ? 'rounded-tr-lg'
                          : index === 0
                            ? 'rounded-tl-lg'
                            : ''
                      } whitespace-nowrap border-cyan-500 border-l py-2 px-4 text-sm font-medium`}
                      aria-current={currentTab === index ? 'page' : undefined}
                    >
                      {tab.name}
                    </button>
                  ))}
                </nav>
              </div>
            </div>
          </div>
          <div className="flex w-full border border-rose-500 bg-gray-50 p-5 min-h-36 rounded-b-md rounded-tr-md mt-[0.px]">
            {tabs[currentTab].description}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Report;
