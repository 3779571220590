import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { toast } from 'sonner';
import InstagramIcon from '../../../../../src/assets/SVG/Instagram-Icon.svg';
import FacebookIcon from '../../../../../src/assets/SVG/Facebook-Icon.svg';
import TikTokIcon from '../../../../../src/assets/tiktok-6338429_640.png';

import {
  IndustryData,
  IndustrySeriesData,
  OptionType,
  TabsDataInteface
} from './types';
import {
  getEngagementRate
  // getEngagementRateForTikTok
} from './component/getEngagementRates/getEngagementRates';
import { getPredictScore } from './component/getPredictScore';
import { getAnalysis } from './component/getAnalysis';
import AnalyzeDataComponent from './component';
import DaysLoader from '../day-of-awareness/component/days-loader';
import { error } from 'console';

const platforms = [
  {
    name: 'Instagram',
    image: InstagramIcon,
    description: `1 Your post has 0 comments and 0 likes.- Your post has a caption "demo" and it is an image type`
  },
  {
    name: 'Facebook',
    image: FacebookIcon,
    description: `2 Your post has 0 comments and 0 likes.- Your post has a caption "demo" and it is an image type`
  },
  {
    name: 'TikTok',
    image: TikTokIcon,
    description: `3 Your post has 0 comments and 0 likes.- Your post has a caption "demo" and it is an image type`
  }
  // More people...
];

type Platform = 'Instagram' | 'Facebook' | 'TikTok';

const AnalyzeData = () => {
  const navigate = useNavigate();
  const [tags, setTags] = useState<any[]>([]);
  const [showLoader, setShowLoader] = useState(false);
  const [showSearchLoader, setSearchShowLoader] = useState(false);
  const [suggestion, setSuggestion] = useState('');
  const [analysis, setAnalysis] = useState<any>({
    indv_comment_analysis: [],
    indv_like_analysis: [],
    ov_com_analysis: '',
    ov_lk_analysis: ''
  });
  const [showSuggestionLoader, setShowSuggestionLoader] = useState();
  const [engagementRateData, setEngagementRateData] = useState<any>([]);
  const [selectedIndustry, setSelectedIndustry] = useState<OptionType[]>([]);
  const [rating, setRating] = useState(0);
  const [feedback, setFeedback] = useState('');
  const [searchText, setSearchText] = useState('');
  const [industryPosts, setIndustryPosts] = useState<any>([{}]);
  const instagramID = localStorage.getItem('InstagramUserID');
  const instaToken = localStorage.getItem('InstagramAccessToken');
  const email = localStorage.getItem('UserEmail');
  const [currentTab, setCurrentTab] = useState(0);
  const [currentPlatform, setCurrentPlatform] = useState(0);
  const [industrySeriesData, setIndustrySeriesData] = useState<
    IndustrySeriesData[]
  >([]);
  const [isInstagramConnected, setInstagramConnected] = useState(false);
  const [isFacebookConnected, setIsFacebookConnected] = useState(false);
  const [isTikTokConnected, setIsTikTokConnected] = useState(false);

  const [userPosts, setUserPosts] = useState([]);
  const [tabsData, setTabsData] = useState<TabsDataInteface[]>([
    { name: 'Analysis', text: '' },
    { name: 'Suggestions', text: '' },
    { name: 'Predictions', text: '' },
    { name: 'Ratings', text: '' }
  ]);
  const [percentageData, setPercentageData] = useState<any>();

  const [connectedPlatforms, setConnectedPlatforms] = useState({
    // Instagram: localStorage.getItem('instaConnected') === 'true',
    Instagram: false,
    Facebook: false,
    TikTok: false
  });
  const isPlatformConnected = (platformIndex: number) => {
    const platformName = platforms[platformIndex].name;

    return connectedPlatforms[platformName as Platform];
  };

  const handleConnect = (platform: Platform) => {
    navigate('/edit-profile');
  };

  const handleSelectPlatforms = (index: any) => {
    setIndustrySeriesData([]);

    setCurrentPlatform(index);
  };

  const handleSelectTab = (index: any) => {
    setCurrentTab(index);
  };

  useEffect(() => {
    if (!!industrySeriesData.length) {
      const tagNames = industrySeriesData
        .map((item) => ({
          id: item.name,
          text: item.name,
          classname: ''
        }))
        .filter((item) => item.id !== 'UserData');
      setTags(tagNames);
    }
    console.log('industrySeriesData', industrySeriesData);
  }, [industrySeriesData]);

  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_BACKEND_URI}/user/get-profile-data/${email}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )
      .then(async (res) => {
        if (res.ok) {
          const response = await res.json();
          console.log('Insta name fetch reponse', response);
          setInstagramConnected(
            response.profileData?.instaUserName ? true : false
          );
          setIsFacebookConnected(
            response.profileData?.fbUsername ? true : false
          );
          setIsTikTokConnected(
            response.profileData?.tikTokUsername ? true : false
          );
          setConnectedPlatforms({
            Instagram: response.profileData?.instaUserName ? true : false,
            Facebook: response.profileData?.fbUsername ? true : false,
            TikTok: response.profileData?.tikTokUsername ? true : false
          });
        }
      })
      .catch((err) => {
        console.log('Error updating password', err);
      });
  }, []);

  useEffect(() => {
    if (rating < 30) {
      setFeedback('We can fix that ');
    } else if (rating <= 50) {
      setFeedback("You 're on the right track ");
    } else if (rating > 50) {
      setFeedback('Yay! Well done!');
    }
  }, [rating]);

  const handleSearch = () => {
    console.log('srch txt', searchText);
    setIndustryPosts([]);
    setSearchShowLoader(true);
    // fetch search post
    fetch(
      `${process.env.REACT_APP_BACKEND_URI}/user/search-analyze-my-data/${instaToken}/${instagramID}/${searchText}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )
      .then(async (res) => {
        console.log('search fetch response', res);
        if (res.ok) {
          const response = await res.json();
          console.log(response);
          setIndustryPosts(response.industryPostsData);
          setSearchShowLoader(false);
        }
      })
      .catch((err) => {
        toast.error('Please make sure you have connected Instagram Account');
        setShowLoader(false);
        console.log('Error fetching analysis details', err);
      });
  };

  const [industryDropDownData, setIndustryDropDownData] = useState<
    OptionType[]
  >([]);
  useEffect(() => {
    console.log('hhhhh', analysis);
  }, [analysis]);
  useEffect(() => {
    let industryData = '';

    //instagram
    if (currentPlatform === 0) {
      // setShowLoader(true);
      industryData = localStorage.getItem('InstagramIndustryPosts') || '';
      const userData = localStorage.getItem('InstagramUserPosts');
      const userPostData = userData ? JSON.parse(userData) : [];
      getEngagementRate(userPostData, 'INSTAGRAM')?.then((res) => {
        setEngagementRateData({ engagementRates: res });
        console.log('res', res);
        return res;
      });
      getAnalysis(userPostData.posts, 'INSTAGRAM')?.then((res: any) => {
        setAnalysis({
          indv_comment_analysis: res?.indv_comment_analysis,
          indv_like_analysis: res?.indv_like_analysis,
          ov_com_analysis: res?.ov_com_analysis,
          ov_lk_analysis: res?.ov_lk_analysis
        });
      });
      getPredictScore(userPostData.posts, 'INSTAGRAM').then((res) => {
        if (res && res.length > 1) {
          // Skip the first index and map over the rest
          // res.slice(1).forEach((score: any) => {
          //   setEngagementRateData((prev: any) => ({
          //     ...prev,
          //     overallscore: [
          //       ...(prev?.overallscore || []), // Preserve previous engagement rates
          //       score.engagementRate
          //     ],
          //     general_suggestions: [
          //       ...(prev?.general_suggestions || []), // Preserve previous suggestions
          //       score.general_suggestion
          //     ],
          //     hashtag_suggestions: [
          //       ...(prev?.hashtag_suggestions || []), // Preserve previous hashtag suggestions
          //       score.hashtag_suggestion
          //     ]
          //   }));
          // });

          // Update suggestions if necessary for the second element or further indices
          setSuggestion(res[0].suggestions);
        }
        setShowLoader(false);
      });
      const engagementRates: any = [];
      setUserPosts(userPostData.posts);
      // setEngagementRateData(getEngagementRate(userPostData));
    } else if (currentPlatform === 1) {
      // setShowLoader(true);
      setEngagementRateData({});
      industryData = localStorage.getItem('FBIndustryPosts') || '';
      const userData = localStorage.getItem('FBUserPosts');
      const userPostData = userData ? JSON.parse(userData) : [];

      getEngagementRate(userPostData, 'FACEBOOK')?.then((res) => {
        setEngagementRateData({ engagementRates: res });

        return res;
      });
      getAnalysis(userPostData.posts, 'FACEBOOK')?.then((res: any) => {
        setAnalysis({
          indv_comment_analysis: res?.indv_comment_analysis,
          indv_like_analysis: res?.indv_like_analysis,
          ov_com_analysis: res?.ov_com_analysis,
          ov_lk_analysis: res?.ov_lk_analysis
        });
      });
      getPredictScore(userPostData.posts, 'FACEBOOK').then((res) => {
        if (res && res.length > 1) {
          // // Skip the first index and map over the rest
          // res.slice(1).forEach((score: any) => {
          //   setEngagementRateData((prev: any) => ({
          //     ...prev,
          //     overallscore: [
          //       ...(prev?.overallscore || []), // Preserve previous engagement rates
          //       score.engagementRate
          //     ],
          //     general_suggestions: [
          //       ...(prev?.general_suggestions || []), // Preserve previous suggestions
          //       score.general_suggestion
          //     ],
          //     hashtag_suggestions: [
          //       ...(prev?.hashtag_suggestions || []), // Preserve previous hashtag suggestions
          //       score.hashtag_suggestion
          //     ]
          //   }));
          // });

          // Update suggestions if necessary for the second element or further indices
          setSuggestion(res[0].suggestions);
        }
        setShowLoader(false);
      });

      setUserPosts(userPostData);
      // setEngagementRateData(getEngagementRate(userPostData, 'FACEBOOK'));
    }
    //tiktokƒ√
    else if (currentPlatform === 2) {
      // setShowLoader(true);
      industryData = localStorage.getItem('TikTokIndustryPosts') || '';
      const userData = localStorage.getItem('TikTokUserPosts');
      const userPostData = userData ? JSON.parse(userData) : [];

      getEngagementRate(userPostData, 'TIKTOK')?.then((res) => {
        setEngagementRateData({ engagementRates: res });
        console.log('res', res);
        return res;
      });
      getPredictScore(userPostData.posts, 'TIKTOK').then((res) => {
        console.log('res1', res);

        if (res && res.length > 1) {
          // Skip the first index and map over the rest
          // res.slice(1).forEach((score: any) => {
          //   setEngagementRateData((prev: any) => ({
          //     ...prev,
          //     engagementRate: [
          //       ...(prev?.engagementRate || []), // Preserve previous engagement rates
          //       score.engagementRate
          //     ],
          //     general_suggestions: [
          //       ...(prev?.general_suggestions || []), // Preserve previous suggestions
          //       score.general_suggestion
          //     ],
          //     hashtag_suggestions: [
          //       ...(prev?.hashtag_suggestions || []), // Preserve previous hashtag suggestions
          //       score.hashtag_suggestion
          //     ]
          //   }));
          // });
          // Update suggestions if necessary for the second element or further indices
          setSuggestion(res[0].suggestions);
        }
        setShowLoader(false);
      });
    }

    // const industryNamesArray =
    //   industryData?.length > 0 ? JSON.parse(industryData) : [];
    // const finalisedArray: any = [];
    // industryNamesArray.map((industry: any) => {
    //   if (!finalisedArray.includes(industry.industry))
    //     finalisedArray.push({
    //       value: industry.industry,
    //       label: industry.industry
    //     });
    // });
    // setIndustryDropDownData((prev: any) => [...prev, ...finalisedArray]);
  }, [currentPlatform]);
  useEffect(() => {
    console.log('UserData engagementRateData', engagementRateData);
    if (engagementRateData?.engagementRates) {
      setIndustrySeriesData((prev) => {
        if (!prev.find((item) => item.name === 'UserData')) {
          return [
            { name: 'UserData', data: engagementRateData?.engagementRates },
            ...prev
          ];
        } else {
          return prev;
        }
      });
    }
  }, [engagementRateData]);
  // data creation for industry graph
  useEffect(() => {
    if (currentPlatform === 0) {
      const industryData = localStorage.getItem('InstagramIndustryPosts');
      const data = industryData ? JSON.parse(industryData) : [];

      //gete selected industries
      // const selectedIndustryNames = selectedIndustry.map(
      //   (industry) => industry.value
      // );
      // const selectedIndustryData = data.filter((industry: any) =>
      //   selectedIndustryNames.includes(industry.industry)
      // );
      const fetchDataAndUpdateState = async (ind: any) => {
        try {
          const res = await getEngagementRate(ind, 'INSTAGRAM');
          setIndustrySeriesData((prev) => {
            // Filter out any existing entries with the same industry name
            const filteredData = prev.filter(
              (item) => item.name !== ind.industry
            );

            // Add the new industry data
            return [
              ...filteredData,
              {
                name: ind.industry,
                data: res // Now you store the resolved value
              }
            ];
          });
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };

      data.forEach((ind: IndustryData) => {
        fetchDataAndUpdateState(ind);
      });
    } else if (currentPlatform === 1) {
      const industryData = localStorage.getItem('FBIndustryPosts');
      const data = industryData ? JSON.parse(industryData) : [];

      //gete selected industries
      // const selectedIndustryNames = selectedIndustry.map(
      //   (industry) => industry.value
      // );
      // const selectedIndustryData = data.filter((industry: any) =>
      //   selectedIndustryNames.includes(industry.industry)
      // );
      const fetchDataAndUpdateState = async (ind: any) => {
        try {
          const res = await getEngagementRate(ind, 'FACEBOOK');
          setIndustrySeriesData((prev) => {
            // Filter out any existing entries with the same industry name
            const filteredData = prev.filter(
              (item) => item.name !== ind.industry
            );

            // Add the new industry data
            return [
              ...filteredData,
              {
                name: ind.industry,
                data: res // Now you store the resolved value
              }
            ];
          });
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
      data.forEach((ind: IndustryData) => {
        fetchDataAndUpdateState(ind);
      });
    } else if (currentPlatform === 2) {
      const industryData = localStorage.getItem('TikTokIndustryPosts');
      const data = industryData ? JSON.parse(industryData) : [];

      //gete selected industries
      // const selectedIndustryNames = selectedIndustry.map(
      //   (industry) => industry.value
      // );

      // const selectedIndustryData = data.filter((industry: any) =>
      //   selectedIndustryNames.includes(industry.industry)
      // );
      const fetchDataAndUpdateState = async (ind: any) => {
        try {
          const res = await getEngagementRate(ind, 'TIKTOK');
          setIndustrySeriesData((prev) => {
            // Filter out any existing entries with the same industry name
            const filteredData = prev.filter(
              (item) => item.name !== ind.industry
            );

            // Add the new industry data
            return [
              ...filteredData,
              {
                name: ind.industry,
                data: res // Now you store the resolved value
              }
            ];
          });
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
      data.forEach((ind: IndustryData) => {
        fetchDataAndUpdateState(ind);
      });
    }
  }, [currentPlatform]);

  // useEffect(() => {
  //   const bodyData = { userPosts: userPosts };
  //   fetch(`${process.env.REACT_APP_BACKEND_URI}/user/analyze-user-posts`, {
  //     method: 'POST',
  //     headers: {
  //       'Content-Type': 'application/json'
  //     },
  //     body: JSON.stringify(bodyData)
  //   })
  //     .then(async (res) => {
  //       if (res.ok) {
  //         const response = await res.json();
  //         console.log('analyze-user-posts response', response);
  //         const analysis = response.analysis;
  //         setTabsData(analysis);
  //         setShowSuggestionLoader(false);
  //       }
  //     })
  //     .catch((err) => {
  //       toast.error('Please make sure you have connected Instagram Account');
  //       console.log('Error fetching analysis details', err);
  //       setShowSuggestionLoader(false);
  //     });
  // }, [userPosts]);
  return (
    <div className="flex flex-col h-full relative lg:pb-10 items-center">
      <div className="flex flex-col items-center w-full mt-10">
        <p className="flex justify-center items-center text-center text-black text-4xl md:text-5xl font-extrabold mb-8">
          {'Analyse my data'}
        </p>
      </div>
      <div className="w-full justify-start px-10 mb-10">
        <p className="text-black text-2xl md:text-2xl font-medium">
          {'Connect Platform'}
        </p>

        <div>
          <div className="sm:hidden">
            <label htmlFor="platforms" className="sr-only">
              Select a Platrom
            </label>
            <select
              id="platforms"
              name="platforms"
              value={currentPlatform}
              onChange={(e) => handleSelectPlatforms(parseInt(e.target.value))}
              className="block w-full rounded-md border-rose-500 py-2 pl-3 pr-10 text-base focus:border-rose-500 focus:outline-none focus:ring-rose-500 sm:text-sm"
            >
              {platforms?.map((platform, index) => (
                <option key={platform.name} value={index}>
                  {platform.name}
                </option>
              ))}
            </select>
          </div>
          <div className="hidden sm:block">
            <div className="flex w-full justify-start items-center border-gray-200 mt-4">
              <nav
                className="flex w-full border border-teal-500 rounded-r-lg rounded-l-lg"
                aria-label="Tabs"
              >
                {platforms?.map((platform, index) => (
                  <button
                    key={platform.name}
                    onClick={() => handleSelectPlatforms(index)}
                    className={`${
                      currentPlatform === index
                        ? 'border-rose-500 bg-rose-500 text-white'
                        : 'text-gray-900 hover:text-gray-900 hover:bg-rose-100'
                    } ${
                      index === 2
                        ? 'rounded-r-lg'
                        : index === 0
                          ? 'rounded-l-lg'
                          : ''
                    } flex w-full items-center justify-center whitespace-nowrap border-teal-500 border-l py-2 px-6 text-sm font-medium`}
                    aria-current={
                      currentPlatform === index ? 'page' : undefined
                    }
                  >
                    <img
                      className="flex w-6 h-6 mr-5"
                      src={platform.image}
                      alt={'details circles'}
                    />
                    <span>{platform.name}</span>
                  </button>
                ))}
              </nav>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-1 w-full mt-3">
          {platforms?.map((platform, index) =>
            currentPlatform === index &&
            !connectedPlatforms[platform.name as Platform] ? (
              <div
                key={platform.name}
                className={`flex flex-col items-center justify-center border border-teal-500 rounded-lg w-full py-10 px-5`}
              >
                <img
                  className="flex w-16 h-16 mr-5 mb-5"
                  src={platform.image}
                  alt={'details circles'}
                />
                <p className="font-semibold">{`Connect your ${platform.name}`}</p>
                <p>Click on Connect Button</p>
                <button
                  className="mt-5 bg-rose-500 text-white w-full max-w-[250px] rounded-md px-12 h-14 text-sm hover:bg-rose-400"
                  onClick={() => handleConnect(platform.name as Platform)}
                >
                  connect
                </button>
              </div>
            ) : null
          )}
        </div>
        <>
          {connectedPlatforms.Instagram === true &&
          currentPlatform === 0 &&
          engagementRateData ? (
            <AnalyzeDataComponent
              industryDropDownData={industryDropDownData}
              setIndustryDropDownData={setIndustryDropDownData}
              currentPlatform={currentPlatform}
              engagementRateData={engagementRateData}
              isInstagramConnected={isInstagramConnected}
              isFacebookConnected={isFacebookConnected}
              isTikTokConnected={isTikTokConnected}
              rating={rating}
              industrySeriesData={industrySeriesData}
              setSelectedIndustry={setSelectedIndustry}
              selectedIndustry={selectedIndustry}
              suggestion={suggestion}
              analysis={analysis}
              tags={tags}
              setTags={setTags}
            />
          ) : (
            ''
          )}
          {connectedPlatforms.Facebook === true &&
          currentPlatform === 1 &&
          engagementRateData ? (
            <AnalyzeDataComponent
              industryDropDownData={industryDropDownData}
              setIndustryDropDownData={setIndustryDropDownData}
              currentPlatform={currentPlatform}
              engagementRateData={engagementRateData}
              isInstagramConnected={isInstagramConnected}
              isFacebookConnected={isFacebookConnected}
              isTikTokConnected={isTikTokConnected}
              rating={rating}
              industrySeriesData={industrySeriesData}
              setSelectedIndustry={setSelectedIndustry}
              selectedIndustry={selectedIndustry}
              suggestion={suggestion}
              analysis={analysis}
              tags={tags}
              setTags={setTags}
            />
          ) : (
            ''
          )}
          {connectedPlatforms.TikTok === true &&
          currentPlatform === 2 &&
          engagementRateData ? (
            <AnalyzeDataComponent
              industryDropDownData={industryDropDownData}
              setIndustryDropDownData={setIndustryDropDownData}
              currentPlatform={currentPlatform}
              engagementRateData={engagementRateData}
              isInstagramConnected={isInstagramConnected}
              isFacebookConnected={isFacebookConnected}
              isTikTokConnected={isTikTokConnected}
              rating={rating}
              industrySeriesData={industrySeriesData}
              setSelectedIndustry={setSelectedIndustry}
              selectedIndustry={selectedIndustry}
              suggestion={suggestion}
              analysis={analysis}
              tags={tags}
              setTags={setTags}
            />
          ) : (
            ''
          )}
        </>
      </div>
      {showLoader && (
        <div className="flex justify-center items-center">
          <DaysLoader />
        </div>
      )}
    </div>
  );
};

export default AnalyzeData;
