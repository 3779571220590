import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  ArrowUpIcon,
  ArrowDownIcon,
  MagnifyingGlassIcon
} from '@heroicons/react/24/solid';
import { toast } from 'sonner';
import Loader from '../../day-of-awareness/component/days-loader';
import { containsOnlySpaces } from '../../../../../utils';
import InstagramIcon from '../../../../..//assets/SVG/Instagram-Icon.svg';
import FacebookIcon from '../../../../..//assets/SVG/Facebook-Icon.svg';
import TikTokIcon from '../../../../../assets/tiktok-6338429_640.png';
import SplineChart from '.././apex-charts/SplineAreaChart';
import StackedLineChart from '.././apex-charts/StackedLineChart';
// import AddIndustry from '.././component/addIndustry';
import MultiSelectDropdown from '.././component/dropdown/industryDropdown';
import YellowCircle from '../../../../../assets/Yellow-Circle-Left.png';

import { TabsDataInteface } from '.././types';
import { AnalyzeDataInterfaceProps } from '.././types';
import TagsInput from './industrysearch';
import AddIndustry from './addIndustry';
const platforms = [
  {
    name: 'Instagram',
    image: InstagramIcon,
    description: `1 Your post has 0 comments and 0 likes.- Your post has a caption "demo" and it is an image type`
  },
  {
    name: 'Facebook',
    image: FacebookIcon,
    description: `2 Your post has 0 comments and 0 likes.- Your post has a caption "demo" and it is an image type`
  },
  {
    name: 'TikTok',
    image: TikTokIcon,
    description: `3 Your post has 0 comments and 0 likes.- Your post has a caption "demo" and it is an image type`
  }
  // More people...
];

type Platform = 'Instagram' | 'Facebook' | 'TikTok';

const AnalyzeDataComponent: React.FC<AnalyzeDataInterfaceProps> = ({
  industryDropDownData,
  setIndustryDropDownData,
  currentPlatform,
  engagementRateData,
  isInstagramConnected,
  isFacebookConnected,
  isTikTokConnected,
  rating,
  industrySeriesData,
  setSelectedIndustry,
  selectedIndustry,
  suggestion,
  analysis,
  tags,
  setTags
}) => {
  const navigate = useNavigate();
  const [showLoader, setShowLoader] = useState(false);
  const [showSearchLoader, setSearchShowLoader] = useState(false);
  const [showSuggestionLoader, setShowSuggestionLoader] = useState();
  // const [engagementRateData, setEngagementRateData] = useState<any>([]);
  const [feedback, setFeedback] = useState('');
  const [searchText, setSearchText] = useState('');
  const [industryPosts, setIndustryPosts] = useState<any>([{}]);
  const instagramID = localStorage.getItem('InstagramUserID');
  const instaToken = localStorage.getItem('InstagramAccessToken');
  const email = localStorage.getItem('UserEmail');
  const instaUsername = localStorage.getItem('InstagramUsername');
  const [currentTab, setCurrentTab] = useState(0);
  const [tabsData, setTabsData] = useState<TabsDataInteface[]>([
    {
      name: 'Analysis',
      text:
        'Individual Comment Analysis:\n' +
        analysis?.indv_comment_analysis?.join('\n') +
        ',' +
        'Individual Like Analysis:\n' +
        analysis?.indv_like_analysis?.join('\n') +
        ',' +
        'ov_com_analysis:\n' +
        analysis?.ov_com_analysis +
        ',' +
        'ov_lk_analysis:\n' +
        analysis?.ov_lk_analysis
    },
    { name: 'Suggestions', text: suggestion },
    { name: 'Predictions', text: '' },
    { name: 'Ratings', text: '' }
  ]);

  const [connectedPlatforms, setConnectedPlatforms] = useState({
    // Instagram: localStorage.getItem('instaConnected') === 'true',
    Instagram: false,
    Facebook: true,
    TikTok: false
  });

  const isPlatformConnected = (platformIndex: number) => {
    const platformName = platforms[platformIndex].name;
    return connectedPlatforms[platformName as Platform];
  };
  const handleSelectTab = (index: any) => {
    setCurrentTab(index);
  };
  useEffect(() => {
    setTabsData((prev: any) =>
      prev.map((tab: any) =>
        tab.name === 'Suggestions' ? { ...tab, text: suggestion } : tab
      )
    );
  }, [suggestion]);
  useEffect(() => {
    if (rating < 30) {
      setFeedback('We can fix that ');
    } else if (rating <= 50) {
      setFeedback("You 're on the right track ");
    } else if (rating > 50) {
      setFeedback('Yay! Well done!');
    }
  }, [rating]);
  useEffect(() => {
    setConnectedPlatforms((prevState) => ({
      ...prevState,
      Instagram: isInstagramConnected
    }));
  }, [isInstagramConnected]);
  useEffect(() => {
    setConnectedPlatforms((prevState) => ({
      ...prevState,
      Facebook: isFacebookConnected
    }));
  }, [isFacebookConnected]);
  useEffect(() => {
    setConnectedPlatforms((prevState) => ({
      ...prevState,
      TikTok: isTikTokConnected
    }));
  }, [isTikTokConnected]);
  // const handleSearch = () => {
  //   console.log('srch txt', searchText);
  //   setIndustryPosts([]);
  //   setSearchShowLoader(true);
  //   // fetch search post
  //   fetch(
  //     `${process.env.REACT_APP_BACKEND_URI}/user/search-analyze-my-data/${instaToken}/${instagramID}/${searchText}`,
  //     {
  //       method: 'GET',
  //       headers: {
  //         'Content-Type': 'application/json'
  //       }
  //     }
  //   )
  //     .then(async (res) => {
  //       console.log('search fetch response', res);
  //       if (res.ok) {
  //         const response = await res.json();
  //         console.log(response);
  //         setIndustryPosts(response.industryPostsData);
  //         setSearchShowLoader(false);
  //       }
  //     })
  //     .catch((err) => {
  //       toast.error('Please make sure you have connected Instagram Account');
  //       setShowLoader(false);
  //       console.log('Error fetching analysis details', err);
  //     });
  // };

  return (
    <>
      <div
        className={`w-full justify-start px-10 mb-10 ${isPlatformConnected(currentPlatform) ? '' : 'hidden'}`}
      >
        <p className="text-black text-2xl md:text-2xl font-medium">
          {'Report and analysis'}
        </p>
        <div className="flex flex-col md:flex-row w-full justify-between items-center md:space-x-6 z-10">
          <div className="w-full max-w-5xl">
            <TagsInput
              currentPlatform={currentPlatform}
              tags={tags}
              setTags={setTags}
            />
          </div>
          {/* <button className="bg-rose-500 text-white w-full max-w-[250px] rounded-md md:px-5 lg:px-12 h-14 text-sm hover:bg-rose-400 mb-2">
            Update
          </button> */}

          {/* <AddIndustry currentPlatform={currentPlatform} /> */}
        </div>
      </div>
      {showLoader ? (
        <Loader screen="analyze-my-data" />
      ) : (
        <div
          className={`w-full px-10 ${isPlatformConnected(currentPlatform) ? '' : 'hidden'}`}
        >
          {/* <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-12 w-full z-50 gap-8 mb-8">
            <div className="col-span-3 flex flex-col justify-center items-center shadow-xl p-5 rounded-lg bg-white border border-teal-400">
              <div className=" flex justify-between items-center w-full mb-6">
                <p className="text-black text-[22px] font-bold">
                  {instaUsername}
                </p>
                {rating < 50 ? (
                  <div className="flex bg-red-100 rounded-lg text-red-800 px-1.5 py-1 text-sm justify-center items-center space-x-1">
                    <ArrowDownIcon className="text-red-500 w-3 h-3" />
                    <p>{rating + '%'}</p>
                  </div>
                ) : (
                  <div className="flex bg-green-100 rounded-lg text-green-800 px-1.5 py-1 text-sm justify-center items-center space-x-1">
                    <ArrowUpIcon className="text-green-500 w-3 h-3" />
                    <p>{rating + '%'}</p>
                  </div>
                )}
              </div>
              <p className="w-full text-black text-sm font-normal">
                {feedback}
              </p>
            </div>
          </div> */}
          <div className="w-full max-w-screen p-5 my-10 border border-teal-500 rounded-lg">
            <div className="w-full flex">
              <p className="w-full text-black text-2xl md:text-2xl font-medium ">
                Graph Comparison With Industry
              </p>
              <div className="w-full flex justify-end space-x-5">
                <button className="border border-teal-500 max-w-fit px-3 w-full rounded-md">
                  Similar Account Comparison
                </button>
                <button className="border border-teal-500 max-w-[100px] w-full rounded-md">
                  April ,2024
                </button>
              </div>
            </div>

            <div className="">
              <StackedLineChart industryDataSeries={industrySeriesData} />
            </div>
          </div>
          <div className="flex flex-col w-full mb-10">
            <p className="mb-2 text-[24px] font-bold">Suggestion</p>

            <div className="">
              <div>
                <div className="sm:hidden">
                  <label htmlFor="tabs" className="sr-only">
                    Select a tab
                  </label>
                  <select
                    id="tabs"
                    name="tabs"
                    value={currentTab}
                    onChange={(e) => handleSelectTab(parseInt(e.target.value))}
                    className="block w-full rounded-md border-rose-500 py-2 pl-3 pr-10 text-base focus:border-rose-500 focus:outline-none focus:ring-rose-500 sm:text-sm"
                  >
                    {tabsData?.map((tab: TabsDataInteface, index) => (
                      <option key={tab.name} value={index}>
                        {tab.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="hidden sm:block">
                  <div className="flex w-full justify-start items-center border-gray-200">
                    <nav
                      className="flex border border-teal-500 rounded-tr-lg rounded-tl-lg"
                      aria-label="Tabs"
                    >
                      {tabsData?.map((tab: TabsDataInteface, index) => (
                        <button
                          key={tab.name}
                          onClick={() => handleSelectTab(index)}
                          className={`${
                            currentTab === index
                              ? 'border-rose-500 bg-rose-500 text-white'
                              : 'text-rose-500 hover:text-white hover:bg-rose-500'
                          } ${
                            index === 3
                              ? 'rounded-tr-lg'
                              : index === 0
                                ? 'rounded-tl-lg'
                                : ''
                          } whitespace-nowrap border-teal-500 border-l py-2 px-4 text-sm font-medium`}
                          aria-current={
                            currentTab === index ? 'page' : undefined
                          }
                        >
                          {tab.name}
                        </button>
                      ))}
                    </nav>
                  </div>
                </div>
              </div>
              <div className="flex w-full border border-rose-500 bg-gray-50 p-5 min-h-36 rounded-b-md rounded-tr-md mt-[0.px]">
                {showSuggestionLoader ? (
                  <div className="flex w-full items-center justify-center ">
                    <Loader />
                  </div>
                ) : (
                  tabsData[currentTab]?.text
                )}
              </div>
            </div>
          </div>
          <div className="flex flex-col w-full">
            <p className="mb-2 text-[24px] font-bold">Explore</p>
            <div className="flex items-center relative">
              <input
                className="w-full max-w-sm pl-8 pr-2 py-2 bg-teal-50 rounded-2xl border border-teal-300 focus:border-[1px] focus:border-teal-500 focus:outline-none focus:ring-0"
                placeholder={'Search'}
                type="search"
                value={searchText}
                onChange={(e) => {
                  setSearchText(e.target.value);
                }}
                onKeyUp={(e) => {
                  if (e.key === 'Enter' && !containsOnlySpaces(searchText)) {
                    // User pressed Enter key
                    // handleSearch();
                  } else if (
                    e.key === 'Enter' &&
                    containsOnlySpaces(searchText)
                  ) {
                    toast.error("Search field can't be empty!");
                  }
                }}
              />
              <MagnifyingGlassIcon className="absolute top-[11px] left-2 text-gray-400 w-5 h-5" />
              {/* <button className="border-none text-blue-500 ml-8">{'Cancel'}</button> */}
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 w-full z-50 gap-4 mt-8">
            {industryPosts?.map((post: any) =>
              post?.media_url ? (
                <div className="relative" key={post?.id}>
                  <img
                    className="w-full md:w-[260px] h-[200px]"
                    src={post?.media_url}
                    alt={'industry post image'}
                  />
                </div>
              ) : (
                ''
              )
            )}
          </div>
          <div className="flex justify-center items-center">
            {showSearchLoader && <Loader />}
          </div>
          <div className="flex w-full max-w-2xl lg:max-w-4xl justify-center items-center mb-20 gap-5">
            <button
              className="flex justify-center items-center  mt-10 px-4 lg:px-10 py-2 bg-white border-2 border-rose-500 rounded-lg text-center text-rose-500 text-lg font-bold mb-5"
              onClick={() => {
                navigate('/todo');
              }}
            >
              {'Back'}
            </button>
            <button
              className="flex w-full max-w-sm justify-center items-center px-0 lg:px-28 py-3 bg-rose-500 hover:bg-rose-400 rounded-lg mt-10 text-center text-white text-lg font-bold mb-5"
              onClick={() => {
                navigate('/todo');
              }}
            >
              {'Next'}
            </button>
          </div>
          <div className="w-full flex flex-col justify-center">
            <img
              className="hidden md:flex absolute w-[220px] h-[180px] lg:w-[330px] lg:h-[300px] -right-10 -rotate-90 bottom-0 -z-10"
              src={YellowCircle}
              alt={'details circles'}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default AnalyzeDataComponent;
