export const setFacebookConnectionStatusInDB = (email: string) => {
  const bodyData = {
    email: email,
    isFacebookLoggedIn: true
  };
  fetch(`${process.env.REACT_APP_BACKEND_URI}/user/update-profile-data`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(bodyData)
  })
    .then(async (res) => {
      console.log('save isFacebbookLoggedIn response', res);
    })
    .catch((err) => {
      console.log('Error isFacebbookLoggedIn ', err);
    });
};
