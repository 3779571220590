export const getAnalysis = (data: any, platform: string) => {
  try {
    const bodyData: any = {
      data: []
    };

    const posts: any = [];
    const analysisUrl = 'https://ai.cluetech.co.uk/gen_analysis';
    data.map((post: any) => {
      posts.push({
        caption: post.caption,
        platform: platform,
        taken_at: post?.taken_at,
        num_likes: post.like_count,
        num_comments: post.comment_count,
        num_reshares: 0
      });
    });

    bodyData.data = posts;
    return fetch(analysisUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(bodyData)
    })
      .then(async (response) => {
        const res = await response.json();
        return res;
      })
      .catch((err) => {
        console.log('Error fetching multi score', err);
      });
  } catch (error) {}
};
