// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Style for each individual tag */
.ReactTags__tag {
    background-color: rgb(244 63 94 );
    border-radius: 4px;
    color: white;
    padding: 5px;
    margin: 5px;
    display: inline-block;
  }
  
  /* Style for the tag's close button (the x icon) */
  .ReactTags__remove {
    cursor: pointer;
    margin-left: 8px;
    color: white;
  }
  
  /* Style for the input field */
  .ReactTags__tagInputField {
    border: none;
    outline: none;
    font-size: 16px;
    width: 100%;
    border: 2px solid #edeaea; /* Example: black border */
    padding: 5px;
    border-radius: 4px;
  }
  
  `, "",{"version":3,"sources":["webpack://./src/pages/home/pages/analyze-data/component/industrysearch/tag.css"],"names":[],"mappings":"AAAA,kCAAkC;AAClC;IACI,iCAAiC;IACjC,kBAAkB;IAClB,YAAY;IACZ,YAAY;IACZ,WAAW;IACX,qBAAqB;EACvB;;EAEA,kDAAkD;EAClD;IACE,eAAe;IACf,gBAAgB;IAChB,YAAY;EACd;;EAEA,8BAA8B;EAC9B;IACE,YAAY;IACZ,aAAa;IAEb,eAAe;IACf,WAAW;IAGX,yBAAyB,EAAE,0BAA0B;IACrD,YAAY;IACZ,kBAAkB;EAJpB","sourcesContent":["/* Style for each individual tag */\n.ReactTags__tag {\n    background-color: rgb(244 63 94 );\n    border-radius: 4px;\n    color: white;\n    padding: 5px;\n    margin: 5px;\n    display: inline-block;\n  }\n  \n  /* Style for the tag's close button (the x icon) */\n  .ReactTags__remove {\n    cursor: pointer;\n    margin-left: 8px;\n    color: white;\n  }\n  \n  /* Style for the input field */\n  .ReactTags__tagInputField {\n    border: none;\n    outline: none;\n    padding: 10px;\n    font-size: 16px;\n    width: 100%;\n  }\n  .ReactTags__tagInputField {\n    border: 2px solid #edeaea; /* Example: black border */\n    padding: 5px;\n    border-radius: 4px;\n  }\n  \n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
