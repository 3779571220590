import React, { useEffect, useState } from 'react';
import { toast } from 'sonner';
import { useLocation, useNavigate } from 'react-router-dom';
import jsPDF from 'jspdf'; // Import jsPDF
import RedCircle from '../../../../../src/assets/Red_Circle_Left.png';
import CustomSelect from '../../pages/add-details/component/CustomSelect';

// Define TypeScript Interfaces
interface UserInterface {
  _id?: string;
  fullname?: string;
  email?: string;
  password?: string;
  emailVerificationToken?: string;
  name?: string;
  customers?: string;
  account?: string;
  industry?: string;
  preferedTone?: string;
  spacesForFilming?: string;
  hearAboutUs?: string;
  businessSummary?: string;
  userDetailAdded?: boolean;
}

interface Post {
  Date: string;
  Idea: string;
  Format: string;
  Caption: string;
  Video_Idea?: string;
  DayName: string;
}

interface WeeklyOrganizedData {
  [week: string]: Post[];
}

interface LocationState {
  weeklyOrganizedData?: WeeklyOrganizedData;
}

const ContentDevelopment = () => {
  const location = useLocation();
  const navigate = useNavigate();
  // Correctly destructure weeklyOrganizedData from location.state
  const { weeklyOrganizedData = {} } = (location.state as LocationState) || {};

  const [contentDevelopment, setContentDevelopment] = useState({
    createMyContent: '',
    howYouCreateYourContent: ''
  });

  const [userData, setUserData] = useState<UserInterface>({
    fullname: '',
    name: '',
    customers: '',
    account: '',
    industry: '',
    preferedTone: '',
    spacesForFilming: '',
    hearAboutUs: '',
    businessSummary: ''
  });

  const email = localStorage.getItem('UserEmail');
  const planId = localStorage.getItem('PlanId');

  const dropDownData = {
    createMyContentOptions: [
      { id: 1, name: 'DIY' },
      { id: 2, name: 'Come to the clue hub' },
      { id: 3, name: 'Book in the clue team' }
    ],
    howYouCreateYourContentOptions: [{ id: 1, name: 'Provide printable list' }]
  };

  // Function to generate PDF
  const generatePDF = (data: WeeklyOrganizedData) => {
    const doc = new jsPDF();
    let yOffset = 10;

    Object.entries(data).forEach(([week, posts]) => {
      if (posts.length === 0) {
        // If no posts for the week, note it
        doc.setFontSize(16);
        doc.text(`Week ${week}`, 10, yOffset);
        yOffset += 10;
        doc.setFontSize(12);
        doc.text('No content planned for this week.', 10, yOffset);
        yOffset += 10;
        return;
      }
      // Add Week as Heading
      doc.setFontSize(16);
      doc.text(`Week ${week}`, 10, yOffset);
      yOffset += 10;

      posts.forEach((post: Post, index: number) => {
        // Add Post Number
        doc.setFontSize(14);
        doc.text(`Post ${index + 1}`, 10, yOffset);
        yOffset += 7;

        // Define the keys you want to include
        const keys: (keyof Post)[] = [
          'Date',
          'DayName',
          'Idea',
          'Video_Idea',
          'Format',
          'Caption'
        ];

        keys.forEach((key) => {
          if (post[key]) {
            // Only add if the key exists and is not empty
            doc.setFontSize(12);
            const value = post[key];
            const text = `${key}: ${value}`;
            const splitText = doc.splitTextToSize(text, 180); // Split text to fit within page width
            doc.text(splitText, 15, yOffset);
            yOffset += splitText.length * 7; // Adjust Y position based on text length
          }
        });

        yOffset += 5; // Add space between posts

        // Check for page overflow
        if (yOffset > 270) {
          doc.addPage();
          yOffset = 10;
        }
      });

      yOffset += 10; // Add space between weeks

      // Check for page overflow
      if (yOffset > 270) {
        doc.addPage();
        yOffset = 10;
      }
    });

    // Save the PDF
    doc.save('ContentPlan.pdf');
  };

  const storeContentDevelopment = async () => {
    const bodyData = {
      planId: planId,
      contentDevelopment: {
        createMyContent: contentDevelopment.createMyContent,
        howYouCreateYourContent: contentDevelopment.howYouCreateYourContent
      },
      lastStep: 'content-development'
    };
    console.log({ bodyData });

    // Check if both fields are selected
    if (
      !contentDevelopment.createMyContent ||
      !contentDevelopment.howYouCreateYourContent
    ) {
      toast.error('Please provide complete details');
      return; // Exit the function if validation fails
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URI}/plan/update`,
        {
          method: 'PATCH',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(bodyData)
        }
      );

      if (response.ok) {
        const data = await response.json();
        console.log({ data });

        if (data?.code === 200) {
          // Check if selected options are "DIY" and "Provide printable list"
          if (
            contentDevelopment.createMyContent === 'DIY' &&
            contentDevelopment.howYouCreateYourContent ===
              'Provide printable list'
          ) {
            if (
              weeklyOrganizedData &&
              Object.keys(weeklyOrganizedData).length > 0
            ) {
              generatePDF(weeklyOrganizedData as WeeklyOrganizedData);
            } else {
              toast.error('No data available to generate PDF');
              console.error(
                '🚀 ~ ContentDevelopment ~ weeklyOrganizedData is empty or undefined'
              );
            }
          }

          // Navigate to the next route
          navigate('/content-plan');
        } else {
          throw new Error(data.status);
        }
      } else {
        throw new Error('Unable to connect to Backend');
      }
    } catch (error: any) {
      toast.error(`${error?.message}`);
      console.log(error.message);
      console.log(error.msg);

      // Handle network errors or exceptions
      console.error('Error:', error);
    }
  };

  useEffect(() => {
    if (email) {
      fetch(
        `${process.env.REACT_APP_BACKEND_URI}/user/get-profile-data/${email}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json'
          }
        }
      )
        .then(async (res) => {
          if (res.ok) {
            const response = await res.json();
            setUserData(response.profileData);
          } else {
            throw new Error('Failed to fetch user data');
          }
        })
        .catch((err) => {
          console.log('Error fetching user data', err);
          toast.error('Failed to fetch user data');
        });
    } else {
      toast.error('User email not found');
    }
  }, [email]);

  return (
    <div className="w-full flex flex-col justify-center">
      <div className="lg:flex min-h-screen w-full relative lg:pb-10">
        <div className="flex flex-col w-full items-center px-5 md:px-0">
          <div className="flex flex-col items-center w-full mt-10">
            <p className="text-black md:text-4xl text-3xl font-medium mb-3">
              {'Content Development'}
            </p>
            <p className="flex justify-center items-center text-center text-black text-4xl md:text-5xl font-extrabold mb-14">
              {'Create my [....]?'}
            </p>
            <div className="w-full max-w-xl px-10 pt-10 pb-16 space-y-5 border border-cyan-500 rounded-lg bg-white">
              <div>
                <label className="text-black text-base font-normal">
                  {'Create my content'}
                </label>
                <CustomSelect
                  data={dropDownData.createMyContentOptions.map((item) => ({
                    id: item.id,
                    name: item.name
                  }))}
                  placeholder={'Options'}
                  onSelect={(selectedItem: any) => {
                    setContentDevelopment((previous) => ({
                      ...previous,
                      createMyContent: selectedItem.name
                    }));
                    if (selectedItem.name === 'Come to the clue hub') {
                      window.location.href = `mailto:hello@cluecontent.com?body=I would like to book into film content at the clue hub here is a link to my content plan.%0D%0AMy details are: %0D%0AName : ${userData.fullname}, %0D%0ACustomers : ${userData.customers}, %0D%0AAccount : ${userData.account},%0D%0AIndustry : ${userData.industry},%0D%0APreferredTone : ${userData.preferedTone},%0D%0ASpaceForFilming : ${userData.spacesForFilming},%0D%0AHearAboutUs : ${userData.hearAboutUs},%0D%0ABusinessSummary : ${userData.businessSummary}&subject=Please can I come to Cambridge to film my content`;
                    }
                    if (selectedItem.name === 'Book in the clue team') {
                      window.location.href = `mailto:hello@cluecontent.com?body=I would like to book into film content at our place here is a link to my content plan.%0D%0AMy details are:%0D%0AName : ${userData.fullname},%0D%0ACustomers : ${userData.customers},%0D%0AAccount : ${userData.account},%0D%0AIndustry : ${userData.industry},%0D%0APreferredTone : ${userData.preferedTone},%0D%0ASpaceForFilming : ${userData.spacesForFilming},%0D%0AHearAboutUs : ${userData.hearAboutUs},%0D%0ABusinessSummary : ${userData.businessSummary}&subject=Please can you come to me to film my content`;
                    }
                  }}
                />
              </div>
              <div>
                <label className="text-black text-base font-normal">
                  {'How you create your content?'}
                </label>
                <CustomSelect
                  data={dropDownData.howYouCreateYourContentOptions.map(
                    (item) => ({
                      id: item.id,
                      name: item.name
                    })
                  )}
                  placeholder={'Options'}
                  onSelect={(selectedItem: any) => {
                    setContentDevelopment((previous) => ({
                      ...previous,
                      howYouCreateYourContent: selectedItem.name
                    }));
                  }}
                />
              </div>
            </div>
            <div className="flex w-fit justify-center items-center gap-5">
              <button
                className="flex justify-center items-center mt-10 px-4 lg:px-10 py-2 bg-white border-2 border-rose-500 rounded-lg text-center text-rose-500 text-lg font-bold mb-5"
                onClick={() => {
                  navigate(-1);
                }}
              >
                {'Back'}
              </button>
              <button
                className="flex w-full justify-center items-center px-0 lg:px-10 py-3 bg-rose-500 hover:bg-rose-400 rounded-lg mt-10 text-center text-white text-lg font-bold mb-5"
                onClick={() => {
                  storeContentDevelopment();
                }}
              >
                {'Next'}
              </button>
            </div>
          </div>
        </div>
        <img
          className="hidden md:flex absolute w-[350px] h-[300px] lg:w-[343px] lg:h-[343px] right-0 -rotate-90 bottom-0 -z-10"
          src={RedCircle}
          alt={'details circles'}
        />
      </div>
    </div>
  );
};

export default ContentDevelopment;
