import { getPostTypeForFrontEnd } from '../getPostType';
export const updateWeeklyContent = (weeklyOrganizedData: any, res: any) => {
  res.output.map((update: any) => {
    Object.keys(weeklyOrganizedData).forEach((key) => {
      weeklyOrganizedData[key].forEach((idea: any, index: number) => {
        if (res.output[index]) {
          idea.Caption = res.output[index].updated_caption; // Update caption
          idea.PredictedImpressions = res.output[index].predicted_impressions;
          idea.Format = getPostTypeForFrontEnd(res.output[index].post_type);
        }
      });
    });
    console.log('weeklyOrganizedData', weeklyOrganizedData);
  });
  return weeklyOrganizedData;
};
