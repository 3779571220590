import { useEffect, useState } from 'react';
import { toast } from 'sonner';
import { useDispatch, UseDispatch } from 'react-redux';
import FacebookLogin from '@greatsumini/react-facebook-login';
import { useNavigate } from 'react-router-dom';
import SignUpCircles from '../../../../assets/Sign-In-Circles.png';
import ClueLogo from '../../../../assets//Clue-Logo.png';
import { setUser } from '../../../../redux/slices/user.slice';
import { setInstagramUserData } from '../../../../redux/slices/instagram.data.slice';
import DaysLoader from '../day-of-awareness/component/days-loader';
import { getFollowersCount } from './component/getFollowersCount';
import { setFacebookConnectionStatusInDB } from './component/setFacebookConnectionStatusInDB';
import { json } from 'stream/consumers';
function EditProfile() {
  // let userEmail = localStorage.getItem('UserEmail');
  const navigate = useNavigate();
  const [name, setName] = useState();
  const [userName, setUserName] = useState();
  const dispatch = useDispatch();
  const [userEmail, setUserEmail] = useState('');
  const [email, setEmail] = useState(localStorage.getItem('UserEmail'));
  const [instaUsername, setInstaUsername] = useState('');
  const [facebookAuthLogin, setFacebookAuthLogin] = useState(false);
  const [fbUsername, setFBUsername] = useState('');
  const [isFBConnected, setIsFBConnected] = useState(false);
  const [showFBConnectionLoader, setFBConnectionLoader] = useState(false);
  const [tiktokUsername, setTikTokUsername] = useState('');
  const [isTikTokConnected, setIsTicTokConnected] = useState(false);
  const [showTikTokConnectionLoader, setShowtTikTokCollectionLoader] =
    useState(false);
  const [password, setPassword] = useState({
    password: '',
    confirmNewPassword: ''
  });
  const [isInstagramConnected, setInstagramConnected] = useState(false);
  const [instaConnectionLoader, setInstaConnectionLoader] = useState(false);

  const handleConnectInsta = async () => {
    setInstaConnectionLoader(true);
    const url = `https://instagram-data1.p.rapidapi.com/user/feed/v2?username=${instaUsername}`;

    const options = {
      method: 'GET',
      headers: {
        'x-rapidapi-key': 'c2e86b0460mshe1146136755bf0ep1b3b42jsnbfb1f7d5b9f9',
        'x-rapidapi-host': 'instagram-data1.p.rapidapi.com'
      }
    };

    try {
      const response = await fetch(url, options);
      const result = await response.json();
      console.log('result', result);
      if (result.items) {
        const posts = result.items.map((post: any) => {
          console.log('id', post.id);
          return {
            id: post.id,
            caption: post.caption.text,
            like_count: post.like_count,
            comment_count: post.comment_count,
            taken_at: post.taken_at,
            media_type: post.media_type
          };
        });
        const finalData = {
          userName: instaUsername,
          followers: await getFollowersCount(instaUsername),
          posts: posts
        };
        localStorage.setItem('InstagramUserPosts', JSON.stringify(finalData));
        dispatch(setInstagramUserData({ items: result.items }));
        const bodyData = {
          email: email,
          instaUserName: instaUsername
        };
        fetch(`${process.env.REACT_APP_BACKEND_URI}/user/update-profile-data`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(bodyData)
        })
          .then(async (res) => {
            if (res.ok) {
              setInstagramConnected(true);
              setInstaConnectionLoader(false);
            }

            console.log('save insta username response', res);
          })
          .catch((err) => {
            console.log('Error updating password', err);
          });

        // setConnectionLoader(false);
      } else {
        setInstaConnectionLoader(false);
        toast.error(
          'Unable to connect please check you instagram username is correct!'
        );
        // setConnectionLoader(false);
      }
    } catch (error) {
      setInstaConnectionLoader(false);
      console.error(error);
    }
  };

  useEffect(() => {
    console.log('chcking connection');
    fetch(
      `${process.env.REACT_APP_BACKEND_URI}/user/get-profile-data/${email}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )
      .then(async (res) => {
        if (res.ok) {
          const response = await res.json();
          console.log('fetch profile data response', response);
          setUserName(response.profileData.fullname);
          setName(response.profileData.fullname);
          setUserEmail(response.profileData.email);
          setInstaUsername(response.profileData?.instaUserName);
          setFBUsername(response?.profileData?.fbUsername);
          setTikTokUsername(response?.profileData?.tikTokUsername);
          setFacebookAuthLogin(
            response?.profileData?.isFacebookLoggedIn
              ? response?.profileData?.isFacebookLoggedIn
              : false
          );
          console.log('dasfsaf', response.profileData?.instaUserName);
          if (response.profileData?.instaUserName) {
            setInstagramConnected(true);
          } else setInstagramConnected(false);

          if (response.profileData?.fbUsername) {
            setIsFBConnected(true);
          } else setIsFBConnected(false);
          if (response.profileData?.tikTokUsername) {
            setIsTicTokConnected(true);
          } else setIsTicTokConnected(false);
        }
      })
      .catch((err) => {
        console.log('Error fetching profile data', err);
      });
    // fetch selected days of awareness
  }, []);
  const updateProfileData = () => {
    //below code is to add functionality to change even single field previously it wasn't allowed
    const changedData: any = {};
    if (name !== userName) changedData.fullname = userName;
    if (email !== userEmail) {
      if (email) {
        changedData.updatedEmail = userEmail;
        localStorage.setItem('UserEmail', userEmail);
        setEmail(email);
      }
    }
    if (
      password.password !== '' &&
      password.confirmNewPassword !== '' &&
      password.password === password.confirmNewPassword
    ) {
      changedData.password = password;
    } else if (password.password !== password.confirmNewPassword) {
      toast.error("Passwords doesn't matched!");
    }
    if (
      changedData.password ||
      changedData.fullname ||
      changedData.updatedEmail
    ) {
      const bodyData = {
        email: email,
        ...changedData
      };

      fetch(`${process.env.REACT_APP_BACKEND_URI}/user/update-profile-data`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(bodyData)
      })
        .then(async (res) => {
          const respData = await res.json();
          console.log('update-profile-data', respData);
          if (res.ok) {
            toast.message('Profile updated');
            const userData: any = {
              fullname: changedData.fullname,
              email: email
            };
            console.log('disptching with', userData);
            dispatch(setUser({ user: userData, token: '' }));
          } else {
            toast.error('Error updating profile');
          }
        })
        .catch((err) => {
          console.log('Error fetching days of awareness', err);
        });
    }
  };
  const disconnectInsta = () => {
    const bodyData = {
      email: email,
      instaUserName: ''
    };
    fetch(`${process.env.REACT_APP_BACKEND_URI}/user/update-profile-data`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(bodyData)
    })
      .then(async (res) => {
        const respData = await res.json();
        console.log('update-profile-data', respData);
        if (res.ok) {
          toast.message('Instagram disconnected.');
          setInstaUsername('');
          setInstagramConnected(false);
        } else {
          toast.error('Unable to disconnect instagram');
        }
      })
      .catch((err) => {
        console.log('Disconnecting instagram error', err);
      });
  };
  const handleConnectFB = async () => {
    setFBConnectionLoader(true);
    const url = `https://facebook-data-api2.p.rapidapi.com/graph/v19.0/${fbUsername}/feed?fields=from%2Cid%2Ccreated_time%2Cmessage%2Cattachments%7Bmedia%2Ctarget%2Ctitle%2Ctype%2Curl%2Csubattachments.limit(100)%7D%2Ccomments.summary(total_count).limit(0)%2Creactions.summary(total_count).limit(0)%2Cshares&limit=12`;

    const options = {
      method: 'GET',
      headers: {
        'x-rapidapi-key': 'f4b5f80ba3mshfb84cb85a80469bp1f2355jsn4dd9a7aa4933',
        'x-rapidapi-host': 'facebook-data-api2.p.rapidapi.com'
      }
    };

    try {
      const response = await fetch(url, options);
      const result = await response.json();
      console.log('result', result);
      if (result.data) {
        const posts = result.data.map((post: any) => {
          return {
            id: post.id,
            caption: post?.message || '',
            like_count: post.reactions?.summary?.total_count,
            comment_count: post?.comments?.count ? post?.comments?.count : 50,
            taken_at: new Date(post.created_time).getTime()
          };
        });
        const finalData = {
          userName: fbUsername,
          // followers: await getFollowersCount(instaUsername),
          posts: posts
        };
        console.log('finalData', finalData);
        localStorage.setItem('FBUserPosts', JSON.stringify(finalData));
        const bodyData = {
          email: email,
          fbUsername: fbUsername
        };
        fetch(`${process.env.REACT_APP_BACKEND_URI}/user/update-profile-data`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(bodyData)
        })
          .then(async (res) => {
            if (res.ok) {
              setIsFBConnected(true);
              setFBConnectionLoader(false);
            }

            console.log('save facebook username response', res);
          })
          .catch((err) => {
            console.log('Error updating password', err);
          });

        // setConnectionLoader(false);
      } else {
        setFBConnectionLoader(false);
        toast.error(
          'Unable to connect please check you facebook username is correct!'
        );
        // setConnectionLoader(false);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const disconnectFB = () => {
    const bodyData = {
      email: email,
      fbUsername: ''
    };
    fetch(`${process.env.REACT_APP_BACKEND_URI}/user/update-profile-data`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(bodyData)
    })
      .then(async (res) => {
        const respData = await res.json();
        console.log('update-profile-data', respData);
        if (res.ok) {
          toast.message('Facebook disconnected.');
          setFBUsername('');
          setIsFBConnected(false);
        } else {
          toast.error('Unable to disconnect facebook');
        }
      })
      .catch((err) => {
        console.log('Disconnecting facebook error', err);
      });
  };
  const handleConnectTikTok = async () => {
    setShowtTikTokCollectionLoader(true);
    const url = `https://tiktok-api6.p.rapidapi.com/user/videos`;
    const bodyData = {
      username: tiktokUsername
    };
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-rapidapi-key': 'c554cb0048mshf13bfa28fd04646p1d9dbbjsnc744831b9308',
        'x-rapidapi-host': 'tiktok-api6.p.rapidapi.com'
      },
      body: JSON.stringify(bodyData)
    };

    try {
      const response = await fetch(url, options);
      const result = await response.json();
      console.log('result', result);
      if (result.videos) {
        const posts = result.videos
          .map((post: any, index: number) => {
            return {
              id: post.video_id,
              caption: post?.description || '',
              comment_count: post?.statistics?.number_of_comments,
              like_count: post?.statistics?.number_of_hearts,
              number_of_plays: post?.statistics?.number_of_plays,
              num_reshares: post?.statistics?.number_of_reposts,
              number_of_saves: post?.statistics?.number_of_saves,
              taken_at: post.create_time
            };
          })
          .slice(0, 12);
        const finalData = {
          userName: tiktokUsername,
          followers: 0,
          posts: posts
        };
        localStorage.setItem('TikTokUserPosts', JSON.stringify(finalData));
        const bodyData = {
          email: email,
          tikTokUsername: tiktokUsername
        };
        fetch(`${process.env.REACT_APP_BACKEND_URI}/user/update-profile-data`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(bodyData)
        })
          .then(async (res) => {
            if (res.ok) {
              setIsTicTokConnected(true);
              setShowtTikTokCollectionLoader(false);
            }

            console.log('save tiktok username response', res);
          })
          .catch((err) => {
            console.log('Error connecting tiktok', err);
          });

        // setConnectionLoader(false);
      } else {
        setShowtTikTokCollectionLoader(false);
        toast.error(
          'Unable to connect please check your tiktok username is correct!'
        );
        // setConnectionLoader(false);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const disconnectTikTok = () => {
    const bodyData = {
      email: email,
      tikTokUsername: ''
    };
    fetch(`${process.env.REACT_APP_BACKEND_URI}/user/update-profile-data`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(bodyData)
    })
      .then(async (res) => {
        const respData = await res.json();
        console.log('update-profile-data', respData);
        if (res.ok) {
          toast.message('Tiktok disconnected.');
          setTikTokUsername('');
          setIsTicTokConnected(false);
        } else {
          toast.error('Unable to disconnect tiktok');
        }
      })
      .catch((err) => {
        console.log('Disconnecting tiktok error', err);
      });
  };
  const deleteAccount = async () => {
    console.log('first', email);
    fetch(`${process.env.REACT_APP_BACKEND_URI}/user/delete-user/${email}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      }
    })
      .then(async (res) => {
        const r = await res.json();
        if (r.status === 'ok') {
          toast.info('Your account is deleted.', { duration: 1000 });
          setTimeout(() => {
            navigate('/login');
          }, 2000);
        } else {
          toast.error('Unable to delete User');
        }
      })
      .catch((err) => {
        console.log('Error fetching profile data', err);
      });
  };
  const signoutFB = async () => {
    const bodyData = {
      email: email,
      isFacebookLoggedIn: false
    };
    fetch(`${process.env.REACT_APP_BACKEND_URI}/user/update-profile-data`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(bodyData)
    })
      .then(async (res) => {
        console.log('save isFacebbookLoggedIn response', res);
        setFacebookAuthLogin(false);
      })
      .catch((err) => {
        console.log('Error isFacebbookLoggedIn ', err);
      });
  };
  return (
    <div className="relative w-full justify-center items-center min-h-screen flex flex-col ">
      <img
        className="absolute w-[400px] h-[393px] left-0 bottom-0 -z-10"
        src={SignUpCircles}
        alt={'details circles'}
      />
      <div className="flex flex-col w-full max-w-2xl items-center px-10 md:px-24">
        <div className="flex flex-col items-center w-full mt-10">
          <img
            className="max-w-[225px] max-h-[82px] mb-[40px]"
            src={ClueLogo}
            alt={'Clue Logo'}
          />
          <p className="text-black text-4xl font-medium">Profile</p>
          <p className="text-black text-5xl font-extrabold mb-[54px]">
            {'Edit your Profile?'}
          </p>
        </div>
        <div className="w-full flex flex-col space-y-4">
          <div className="relative space-y-2">
            <label className="text-sm font-normal">Name</label>
            <input
              className="w-full pl-5 pr-10 relative h-14 bg-white rounded-lg border border-teal-500 mb-5 focus:outline-1 focus:outline-teal-500"
              placeholder="Qorem ipsum dolor sit "
              value={userName}
              onChange={(e: any) => {
                setUserName(e.target.value);
              }}
            />
          </div>
          <div className="relative space-y-2">
            <label className="text-sm font-normal">Email Address</label>
            <input
              className="w-full px-5 relative h-14 bg-white rounded-lg border border-teal-500 mb-5 focus:outline-1 focus:outline-teal-500 text-gray-500"
              placeholder="Enter email"
              value={userEmail}
              onChange={(e: any) => {
                setUserEmail(e.target.value);
              }}
              disabled={true}
            />
          </div>
          <div className=" space-y-2">
            <label className="text-sm font-normal">Change Password</label>
            <input
              className="w-full px-5 relative h-14 bg-white rounded-lg border border-teal-500 mb-5 focus:outline-1 focus:outline-teal-500"
              placeholder="Enter new password"
              value={password.password}
              onChange={(e: any) => {
                setPassword((prev) => ({ ...prev, password: e.target.value }));
              }}
              type={'password'}
            />
          </div>
          <div className=" space-y-2">
            <label className="text-sm font-normal">Confirm New Password</label>
            <input
              className="w-full px-5 relative h-14 bg-white rounded-lg border border-teal-500 mb-5 focus:outline-1 focus:outline-teal-500"
              placeholder="Confirm password"
              value={password.confirmNewPassword}
              onChange={(e: any) => {
                setPassword((prev) => ({
                  ...prev,
                  confirmNewPassword: e.target.value
                }));
              }}
              type={'password'}
            />
          </div>
          {!facebookAuthLogin ? (
            <FacebookLogin
              appId="1490556441489744"
              children={!facebookAuthLogin && <div>Facebook Login</div>}
              className={
                !facebookAuthLogin
                  ? 'w-full h-14 bg-rose-500 hover:bg-rose-400 rounded-lg text-white text-center  text-sm font-medium'
                  : 'hidden'
              }
              onSuccess={(response) => {
                console.log('Login Success!', response);
                console.log('**first', response.accessToken);
                setFacebookAuthLogin(true);
                setFacebookConnectionStatusInDB(email || '');
                // get long lived token
              }}
              onFail={(error) => {
                console.log('Login Failed!', error);
              }}
              onProfileSuccess={(response) => {
                console.log('Get Profile Success!', response);
              }}
            ></FacebookLogin>
          ) : (
            <button
              className="w-full h-14 bg-rose-500 hover:bg-rose-400 rounded-lg text-white text-center  text-sm font-medium mt-4"
              type="submit"
              onClick={signoutFB}
            >
              Facebook Sign out
            </button>
          )}

          {facebookAuthLogin && (
            <>
              <div className="flex justify-between items-end space-x-5">
                {isInstagramConnected ? (
                  <>
                    <button className="w-full h-14 bg-green-500 hover:bg-green-400 rounded-lg text-white text-center  text-sm font-medium">
                      Connected as {instaUsername}
                    </button>
                  </>
                ) : (
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      handleConnectInsta();
                    }}
                    className="flex w-full flex-col justify-center items-center text-center"
                  >
                    <input
                      className="w-full px-5 relative h-14 bg-white rounded-lg border border-slate-300 mb-5 focus:outline-1 focus:outline-cyan-500"
                      placeholder="instagram username"
                      value={instaUsername}
                      required
                      onChange={(e: any) => {
                        setInstaUsername(e.target.value);
                      }}
                    />
                    {instaConnectionLoader && <DaysLoader />}
                    <button
                      className="w-full h-14 bg-rose-500 hover:bg-rose-400 rounded-lg text-white text-center  text-sm font-medium mt-4"
                      type="submit"
                    >
                      Connect
                    </button>
                  </form>
                )}
                {isInstagramConnected && (
                  <button
                    className="w-full h-14  bg-rose-500 hover:bg-rose-400 rounded-lg text-white text-center  text-sm font-medium mt-5"
                    onClick={() => {
                      disconnectInsta();
                    }}
                  >
                    Disconnect Instagram
                  </button>
                )}
              </div>
              <div className="flex justify-between items-end space-x-5">
                {isFBConnected ? (
                  <>
                    <button className="w-full h-14 bg-green-500 hover:bg-green-400 rounded-lg text-white text-center  text-sm font-medium">
                      Connected as {fbUsername}
                    </button>
                  </>
                ) : (
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      handleConnectFB();
                    }}
                    className="flex w-full flex-col justify-center items-center text-center"
                  >
                    <input
                      className="w-full px-5 relative h-14 bg-white rounded-lg border border-slate-300 mb-5 focus:outline-1 focus:outline-cyan-500"
                      placeholder="Facebook username"
                      value={fbUsername}
                      required
                      onChange={(e: any) => {
                        setFBUsername(e.target.value);
                      }}
                    />
                    {showFBConnectionLoader && <DaysLoader />}
                    <button
                      className="w-full h-14 bg-rose-500 hover:bg-rose-400 rounded-lg text-white text-center  text-sm font-medium mt-4"
                      type="submit"
                    >
                      Connect
                    </button>
                  </form>
                )}
                {isFBConnected && (
                  <button
                    className="w-full h-14  bg-rose-500 hover:bg-rose-400 rounded-lg text-white text-center  text-sm font-medium mt-5"
                    onClick={() => {
                      disconnectFB();
                    }}
                  >
                    Disconnect Facebook
                  </button>
                )}
              </div>
              <div className="flex justify-between items-end space-x-5">
                {isTikTokConnected ? (
                  <>
                    <button className="w-full h-14 bg-green-500 hover:bg-green-400 rounded-lg text-white text-center  text-sm font-medium">
                      Connected as {tiktokUsername}
                    </button>
                  </>
                ) : (
                  <form
                    onSubmit={(e) => {
                      e.preventDefault();
                      handleConnectTikTok();
                    }}
                    className="flex w-full flex-col justify-center items-center text-center"
                  >
                    <input
                      className="w-full px-5 relative h-14 bg-white rounded-lg border border-slate-300 mb-5 focus:outline-1 focus:outline-cyan-500"
                      placeholder="TikTok username"
                      value={tiktokUsername}
                      required
                      onChange={(e: any) => {
                        setTikTokUsername(e.target.value);
                      }}
                    />
                    {showTikTokConnectionLoader && <DaysLoader />}
                    <button
                      className="w-full h-14 bg-rose-500 hover:bg-rose-400 rounded-lg text-white text-center  text-sm font-medium mt-4"
                      type="submit"
                    >
                      Connect
                    </button>
                  </form>
                )}
                {isTikTokConnected && (
                  <button
                    className="w-full h-14  bg-rose-500 hover:bg-rose-400 rounded-lg text-white text-center  text-sm font-medium mt-5"
                    onClick={() => {
                      disconnectTikTok();
                    }}
                  >
                    Disconnect TikTok
                  </button>
                )}
              </div>
            </>
          )}
        </div>
        <button
          className="w-full h-14  bg-rose-500 hover:bg-rose-400 rounded-lg text-white text-center  text-sm font-medium mt-5"
          onClick={() => {
            deleteAccount();
          }}
        >
          Delete Account
        </button>
        <div className="flex w-full justify-center items-center gap-5">
          <button
            className="flex justify-center items-center  my-8  px-4 lg:px-10 py-2 bg-white border-2 border-rose-500 rounded-lg text-center text-rose-500 text-lg font-bold"
            onClick={() => {
              navigate(-1);
            }}
          >
            {'Back'}
          </button>
          <button
            className="w-full px-5 relative h-14 bg-rose-500 hover:bg-rose-400 rounded-lg my-8 text-center text-white text-lg font-bold"
            onClick={() => {
              updateProfileData();
            }}
          >
            {'Save Changes'}
          </button>
        </div>
      </div>
    </div>
  );
}

export default EditProfile;
