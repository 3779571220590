export const getPredictScore = (userData: any, platform: string) => {
  const url = 'https://ai.cluetech.co.uk/mulitple_predict';
  let bodyData = { model_version: 'v0.1', data: [] };
  const posts: any = [];
  userData?.map((user: any) => {
    posts.push({ caption: user.caption, platform: platform });
  });
  bodyData.data = posts;
  const engagementRates: any = [];
  return fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(bodyData)
  })
    .then(async (response) => {
      const res = await response.json();
      engagementRates.push({ suggestions: res.suggestions });
      res.result.map((post: any) => {
        engagementRates.push({
          engagementRate: Math.ceil(post.output.slice(0, -1)),
          general_suggestion: post.general_suggestions,
          hashtag_suggestion: post.hashtag_suggestion
        });
      });
      console.log('engagementRates', engagementRates);
      return engagementRates;
    })
    .catch((err) => {
      console.log('err', err);
    });
};
