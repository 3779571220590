import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'sonner';
import {
  PlusCircleIcon,
  TrashIcon,
  ArrowPathIcon,
  PencilSquareIcon
} from '@heroicons/react/24/solid';
import DropDown from '../select-content-plans/dropdown';
import DaysLoader from '../../day-of-awareness/component/days-loader';
interface ContentPlan {
  planName: string;
  planDate: string;
}
interface ContentInterface {
  Date: string;
  'Video Idea': string;
  Idea: string;
  Format: string;
  Caption: string;
}

const SelectContentPlan = () => {
  const navigate = useNavigate();
  const [editIndex, setEditIndex] = useState<number | null>(null);
  const [editedContentHead, setEditedContentHead] = useState<string>('');
  const [contentData, setContentData] = useState<any>([]);
  const [showLoader, setShowLoader] = useState(true);
  const [initialContentData, setInitialContentData] = useState<any>([]);
  const [selectedMonthYear, setSelectedMonthYear] = useState<string>('All');
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const [deleteIndex, setDeleteIndex] = useState<number | null>(null);
  const [deleteContentId, setDeleteContentId] = useState('');
  const email = localStorage.getItem('UserEmail');
  useEffect(() => {
    handleSortChange(selectedMonthYear);
  }, [selectedMonthYear]);

  const handleEditClick = (
    index: number,
    planName: string,
    event: React.MouseEvent
  ) => {
    event.stopPropagation();
    setEditIndex(index);
    setEditedContentHead(planName);
  };

  const handleSaveClick = (index: number, event: React.MouseEvent) => {
    event.stopPropagation();
    const updatedContentData = [...contentData];
    updatedContentData[index].contentPlan[0].Format = editedContentHead;
    setContentData(updatedContentData);
    setEditIndex(null);
  };

  const handleDeleteClick = (
    index: number,
    event: React.MouseEvent,
    contentId: string
  ) => {
    event.stopPropagation();
    setDeleteContentId(contentId);
    setDeleteIndex(index);
    setShowConfirmation(true);
  };

  const confirmDelete = () => {
    if (deleteIndex !== null) {
      try {
        //delete content from backend
        fetch(
          `${process.env.REACT_APP_BACKEND_URI}/user/delete-content/${deleteContentId}/${email}`,
          {
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json'
            }
          }
        ).then((response) => {
          if (response.ok) {
            const updatedContentData = contentData.filter(
              (_: any, i: number) => i !== deleteIndex
            );
            setContentData(updatedContentData);
            setDeleteIndex(null);
            setShowConfirmation(false);
            toast.info('Content deleted!');
          } else {
            toast.info('Unable to delete content.');
          }
        });
      } catch (error) {
        console.log('Unable to delete content from backend', error);
      }
    }
  };

  const cancelDelete = () => {
    setDeleteIndex(null);
    setShowConfirmation(false);
  };

  const handleInputClick = (event: React.MouseEvent) => {
    event.stopPropagation();
  };

  const months = [
    'All',
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];

  const handleSortChange = (monthYear: string) => {
    setSelectedMonthYear(monthYear);

    if (monthYear === 'All') {
      setContentData(initialContentData);
    } else {
      const [monthName, year] = monthYear.split(' ');
      const monthIndex = months.indexOf(monthName);
      const monthString = monthIndex.toString().padStart(2, '0'); // No +1 needed, monthIndex is 0-based

      const filteredData = initialContentData.filter((content: any) => {
        const [day, contentMonth, contentYear] =
          content?.creationDate?.split('/');
        const yearString = contentYear.slice(-2); // Last two digits of the year
        return contentMonth === monthString && yearString === year.slice(-2);
      });

      console.log('filteredData:', filteredData);
      setContentData(filteredData);
    }
  };

  useEffect(() => {
    fetch(
      `${process.env.REACT_APP_BACKEND_URI}/plan/get-content-plans/${email}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json'
        }
      }
    )
      .then(async (res) => {
        if (res.ok) {
          const data = await res.json();
          console.log('first', data.contentPlans);
          setContentData(data.contentPlans);
          setInitialContentData(data.contentPlans);
          data.contentPlans.map((con: any) => {});

          setShowLoader(false);
        } else {
          setShowLoader(false);
        }
      })
      .catch((err) => {
        console.log('Error fetching content plan', err);
        toast.error('Error fetching content plans!');
        setShowLoader(false);
      });
  }, []);
  return (
    <div className="relative w-full items-center min-h-screen flex flex-col">
      <div className="flex flex-col w-full max-w-7xl px-10 bg-white mb-10">
        <div className="flex flex-col items-center w-full mt-10">
          <p className="text-black text-3xl md:text-5xl font-extrabold mb-[54px] text-center">
            {'Content Plans'}
          </p>

          <div className="flex justify-between items-center w-full">
            <p className="text-black text-lg md:text-2xl font-medium">
              Select Content Plans
            </p>
            <div className="flex items-center space-x-4">
              <DropDown
                selectedMonthYear={selectedMonthYear}
                setSelectedMonthYear={handleSortChange}
                months={months}
              />
              <button
                className="flex justify-center items-center w-fit px-8 rounded-md border text-white bg-teal-500 border-teal-500 hover:bg-teal-400 h-10"
                onClick={() => {
                  navigate('/create-plan');
                }}
              >
                <PlusCircleIcon className="text-white w-5 h-5 mr-3" />
                <span>Create Content Plan</span>
              </button>
            </div>
          </div>
          <div className="w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-8 mt-10">
            {contentData?.map((content: any, index: number) => (
              <div
                key={index}
                className="flex flex-col space-y-5 justify-between lg:min-w-[250px] lg:min-h-[200px] h-full max-h-fit p-4 border border-teal-500 rounded-lg cursor-pointer hover:bg-teal-50 hover:border-spacing-2 shadow-lg hover:shadow-2xl"
                onClick={() =>
                  navigate('/weekly-content-strategy', {
                    state: { planId: content.planId }
                  })
                }
              >
                {editIndex === index ? (
                  <input
                    type="text"
                    value={editedContentHead}
                    onClick={handleInputClick}
                    onChange={(e) => setEditedContentHead(e.target.value)}
                    className="font-semibold w-full rounded-md border-rose-500 focus:border-rose-500 outline-none ring-0 focus:ring-0"
                  />
                ) : (
                  <p className="font-semibold">
                    {content?.contentPlan[0]?.Format}
                  </p>
                )}
                <p className="text-gray-700 whitespace-pre-line">
                  {content.creationDate}
                </p>

                <div className="flex justify-between items-center mt-5">
                  {editIndex === index ? (
                    <button
                      className="flex justify-center items-center px-2 py-1 text-white rounded-md bg-teal-500"
                      onClick={(e) => handleSaveClick(index, e)}
                    >
                      <ArrowPathIcon className="text-white w-5 h-5 mr-1" />
                      <span>Update</span>
                    </button>
                  ) : (
                    <button
                      className="flex justify-center items-center px-2 py-1 text-teal-500 hover:border hover:border-teal-500 rounded-md"
                      onClick={(e) =>
                        handleEditClick(index, content.contentPlan[0].Format, e)
                      }
                    >
                      <PencilSquareIcon className="text-teal-500 w-5 h-5 mr-1" />
                      <span>Edit</span>
                    </button>
                  )}
                  <button
                    className="flex justify-center items-center px-2 py-1 text-red-500 hover:border hover:border-red-500 rounded-md"
                    onClick={(e) => handleDeleteClick(index, e, content._id)}
                  >
                    <TrashIcon className="text-red w-5 h-5 mr-1" />
                    <span>Delete</span>
                  </button>
                </div>
              </div>
            ))}
          </div>
          {showLoader ? <DaysLoader /> : ''}
        </div>
      </div>

      {showConfirmation && (
        <div
          className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full"
          id="my-modal"
        >
          <div className="relative top-1/4 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
            <div className="mt-3 text-center">
              <h3 className="text-lg leading-6 font-bold text-gray-900">
                Are you sure you want to delete it?
              </h3>
              <div className="mt-2 px-7 py-3">
                <p className="text-sm text-red-500">
                  This action cannot be undone.
                </p>
              </div>
              <div className="items-center px-4 py-3">
                <button
                  id="ok-btn"
                  className="px-4 py-2 bg-red-500 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-300"
                  onClick={confirmDelete}
                >
                  Yes, Delete
                </button>
                <button
                  id="cancel-btn"
                  className="px-4 py-2 bg-teal-500 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-teal-400 focus:outline-none focus:ring-2 focus:ring-teal-500 mt-2"
                  onClick={cancelDelete}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SelectContentPlan;
