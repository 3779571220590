import React, { useEffect, useState, useRef } from 'react';
import { WithContext as ReactTags } from 'react-tag-input';
// import AddIndustry from '../addIndustry';
import { toast } from 'sonner';
import { getFollowersCount } from '../../../edit-profile/component/getFollowersCount';
import './tag.css';
import { AddIndustryProps } from '../../types';
const TagsInput: React.FC<AddIndustryProps> = ({
  currentPlatform,
  tags,
  setTags
}) => {
  const [tag, setTag] = useState<any>();

  const [industry, setIndustry] = useState('');
  const KeyCodes = {
    comma: 188,
    enter: 13
  };
  const firstRender = useRef(true);
  const delimiters = [KeyCodes.comma, KeyCodes.enter];
  const deleteFromLocal = (industryName: string) => {
    if (currentPlatform === 0) {
      const industryData = localStorage.getItem('InstagramIndustryPosts') || '';
      const industryDataArray =
        industryData.length > 0 ? JSON.parse(industryData) : [];
      const fillterData = industryDataArray.filter(
        (post: any) => post.industry !== industryName
      );
      localStorage.setItem(
        'InstagramIndustryPosts',
        JSON.stringify(fillterData)
      );
    }
    if (currentPlatform === 1) {
      const industryData = localStorage.getItem('FBIndustryPosts') || '';
      const industryDataArray =
        industryData.length > 0 ? JSON.parse(industryData) : [];
      const fillterData = industryDataArray.filter(
        (post: any) => post.industry !== industryName
      );
      localStorage.setItem('FBIndustryPosts', JSON.stringify(fillterData));
    }
    if (currentPlatform === 2) {
      const industryData = localStorage.getItem('TikTokIndustryPosts') || '';
      const industryDataArray =
        industryData.length > 0 ? JSON.parse(industryData) : [];
      const fillterData = industryDataArray.filter(
        (post: any) => post.industry !== industryName
      );
      localStorage.setItem('TikTokIndustryPosts', JSON.stringify(fillterData));
    }
  };
  const handleDelete = (i: any) => {
    console.log('handleDelete', tags[i].text);
    setTags(tags.filter((tag: any, index: any) => index !== i));
    deleteFromLocal(tags[i].text);
    window.location.reload();
  };

  const handleAddition = async (tag: any) => {
    console.log('first', tag);
    setTag(tag);
    setIndustry(tag.text);
  };
  useEffect(() => {
    if (firstRender.current) {
      firstRender.current = false;
      return;
    }
    if (currentPlatform === 0) {
      getInstagramIndustryData();
    } else if (currentPlatform === 1) {
      getFBIndustryData();
    } else if (currentPlatform === 2) {
      getTikTokIndustryData();
    }
  }, [industry]);

  const handleDrag = (tag: any, currPos: any, newPos: any) => {
    const newTags = tags.slice();
    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, tag);
    setTags(newTags);
  };
  const getInstagramIndustryData = async () => {
    console.log('getInstagramIndustryData', industry);
    {
      const url = `https://instagram-data1.p.rapidapi.com/user/feed/v2?username=${industry}`;
      const options = {
        method: 'GET',
        headers: {
          'x-rapidapi-key':
            'c2e86b0460mshe1146136755bf0ep1b3b42jsnbfb1f7d5b9f9',
          'x-rapidapi-host': 'instagram-data1.p.rapidapi.co'
        }
      };

      try {
        const response = await fetch(url, options);
        const result = await response.json();
        if (result.items) {
          // set industry data
          const industryData =
            localStorage.getItem('InstagramIndustryPosts') || '';
          const industryDataArray =
            industryData.length > 0 ? JSON.parse(industryData) : [];
          const finalArray = result.items.map((post: any) => {
            return {
              id: post.id,
              caption: post.caption.text,
              like_count: post.like_count,
              comment_count: post.comment_count,
              taken_at: post.taken_at
            };
          });

          const names = industryDataArray.map((post: any) => post.industry);
          console.log('names', names);
          if (!names.includes(industry)) {
            const newIndustry = {
              industry: industry,
              followers: await getFollowersCount(industry),
              posts: finalArray
            };
            industryDataArray.push(newIndustry);
            localStorage.setItem(
              'InstagramIndustryPosts',
              JSON.stringify(industryDataArray)
            );
            // setSelectedIndustry(prev => )
            setTags([...tags, tag]);
            toast.info('Industry Added.');
            window.location.reload();
          } else {
            toast.info('Industry already added.');
          }
        } else {
          toast.error('Unable to add industry.');
        }
      } catch (error) {
        toast.error('Unable to add industry.');

        console.error(error);
      }
    }
  };
  const getFBIndustryData = async () => {
    const url = `https://facebook-data-api2.p.rapidapi.com/graph/v19.0/${industry}/feed?fields=from%2Cid%2Ccreated_time%2Cmessage%2Cattachments%7Bmedia%2Ctarget%2Ctitle%2Ctype%2Curl%2Csubattachments.limit(100)%7D%2Ccomments.summary(total_count).limit(0)%2Creactions.summary(total_count).limit(0)%2Cshares&limit=12`;

    const options = {
      method: 'GET',
      headers: {
        'x-rapidapi-key': 'f4b5f80ba3mshfb84cb85a80469bp1f2355jsn4dd9a7aa4933',
        'x-rapidapi-host': 'facebook-data-api2.p.rapidapi.com'
      }
    };

    try {
      const response = await fetch(url, options);
      const result = await response.json();
      console.log('result', result);
      if (result.data) {
        const industryData = localStorage.getItem('FBIndustryPosts') || '';
        const industryDataArray =
          industryData.length > 0 ? JSON.parse(industryData) : [];
        const finalArray = result.data.map((post: any) => {
          return {
            id: post.id,
            caption: post?.message || '',
            like_count: post.reactions?.summary?.total_count,
            comment_count: post?.comments?.count ? post?.comments?.count : 50,
            taken_at: new Date(post.created_time).getTime()
          };
        });
        const names = industryDataArray.map((post: any) => post.industry);

        if (!names.includes(industry)) {
          industryDataArray.push({
            industry: industry,
            posts: finalArray
          });
          localStorage.setItem(
            'FBIndustryPosts',
            JSON.stringify(industryDataArray)
          );
          setTags([...tags, tag]);
          toast.info('Industry Added.');
          window.location.reload();
        } else {
          toast.info('Industry already added.');
        }
      } else {
        toast.error('Industry Not Found!');
      }
    } catch (error) {
      console.error(error);
    }
  };
  const getTikTokIndustryData = async () => {
    const url = `https://tiktok-api6.p.rapidapi.com/user/videos`;
    const bodyData = {
      username: industry
    };
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'x-rapidapi-key': 'f4b5f80ba3mshfb84cb85a80469bp1f2355jsn4dd9a7aa4933',
        'x-rapidapi-host': 'tiktok-api6.p.rapidapi.com'
      },
      body: JSON.stringify(bodyData)
    };

    try {
      const response = await fetch(url, options);
      const result = await response.json();
      console.log('result', result);
      if (result.videos) {
        const industryData = localStorage.getItem('TikTokIndustryPosts') || '';
        const industryDataArray =
          industryData.length > 0 ? JSON.parse(industryData) : [];
        const finalArray = result.videos
          .map((post: any, index: number) => {
            return {
              id: post.video_id,
              caption: post?.description || '',
              comment_count: post?.statistics?.number_of_comments,
              like_count: post?.statistics?.number_of_hearts,
              number_of_plays: post?.statistics?.number_of_plays,
              num_reshares: post?.statistics?.number_of_reposts,
              number_of_saves: post?.statistics?.number_of_saves,
              taken_at: post.create_time
            };
          })
          .slice(0, 12);
        const names = industryDataArray.map((post: any) => {
          return post.industry;
        });
        if (!names.includes(industry)) {
          const finalData = {
            industry: industry,
            followers: 0,
            posts: finalArray
          };
          industryDataArray.push(finalData);
          localStorage.setItem(
            'TikTokIndustryPosts',
            JSON.stringify(industryDataArray)
          );

          toast.info('TikTok account added.');
          setTags([...tags, tag]);
          window.location.reload();
        } else {
          toast.info('Industry already added.');
        }
      }
    } catch (error) {
      toast.error('Unable to add tiktok account.');
    }
  };
  return (
    <div>
      <ReactTags
        tags={tags}
        handleDelete={handleDelete}
        handleAddition={handleAddition}
        handleDrag={handleDrag}
        delimiters={delimiters}
        placeholder="Enter industry name"
      />
    </div>
  );
};

export default TagsInput;
